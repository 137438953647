// src/components/jha/JHAInstructionTable.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Paper, Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function JHAInstructionTable({ jhaData, onSelectRecord }) {
    const [isNarrow, setIsNarrow] = useState(false);

    useEffect(() => {
        function checkWidth() {
            setIsNarrow(window.innerWidth < 900);
        }
        window.addEventListener('resize', checkWidth);
        checkWidth();
        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    // Преобразуем записи к удобному формату,
    // чтобы DataGrid не ломался, если получит одиночный объект
    const rows = useMemo(() => {
        if (!jhaData) return [];
        const arr = Array.isArray(jhaData) ? jhaData : [jhaData];
        return arr.map((item) => ({
            // обязательное поле "id" для DataGrid
            id: item.id,
            jobNumber: item.stepTwo?.jobNumber || '',
            jobSiteName: item.stepTwo?.jobSiteName || '',
            status: item.status || '',
            date: item.stepTwo?.date || '',
            originalData: item,
        }));
    }, [jhaData]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'jobNumber', headerName: 'Job Number', flex: 2 },
        { field: 'jobSiteName', headerName: 'Job Site Name', flex: 2 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params) => (
                <Chip label={params.value} color="primary" variant="outlined" />
            ),
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 2,
        },
        {
            field: 'copy',
            headerName: '',
            flex: 1,
            sortable: false,
            renderCell: () => (
                <IconButton size="small">
                    <ContentCopyIcon fontSize="inherit" />
                </IconButton>
            ),
        },
    ];

    const displayColumns = isNarrow
        ? columns.filter((c) => c.field !== 'jobSiteName')
        : columns;

    return (
        <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={displayColumns}
                    autoHeight
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 50, 100]}
                    disableSelectionOnClick
                    getRowId={(row) => row.id}
                    // При клике возвращаем оригинальный объект
                    onRowClick={(params) => onSelectRecord(params.row.originalData)}
                    rowHeight={70}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            borderBottom: '1px solid #ccc',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: '1px solid #eee',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            lineHeight: '1.3',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#fafafa',
                        },
                    }}
                />
            </Box>
        </Paper>
    );
}
