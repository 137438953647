import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    IconButton
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeDB } from '../../../config/db';
import { JHA_STORE } from '../../../config/constants';
import StepHeader from '../StepHeader';
import SignatureModal from '../SignatureModal';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    ExpandLess,
    ExpandMore,
    Handyman,
    BuildCircle,
    ShieldMoon,
    Circle,
    CheckCircle,
    BugReport,
    FireExtinguisher,
    Bolt,
    ArrowDownward,
    Construction,
    Build,
    DirectionsCar,
    AcUnit,
    VolumeUp,
    DeviceThermostat,
    ReportProblem,
    Check
} from '@mui/icons-material';
import { styled } from '@mui/system';

/** ICONS & COLORS */
const iconColor = { color: '#fff' };
const colorMap = {
    Biological: { bg: '#9e9e9e', icon: <BugReport sx={iconColor} /> },
    'Chemical/Fire': { bg: '#ff5722', icon: <FireExtinguisher sx={iconColor} /> },
    Electrical: { bg: '#ffc107', icon: <Bolt sx={iconColor} /> },
    Gravity: { bg: '#3f51b5', icon: <ArrowDownward sx={iconColor} /> },
    'Motion/Line of Fire': { bg: '#ff9800', icon: <Construction sx={iconColor} /> },
    Mechanical: { bg: '#03a9f4', icon: <Build sx={iconColor} /> },
    'Vehicles/Equipment': { bg: '#9e9e9e', icon: <DirectionsCar sx={iconColor} /> },
    'Weather/Temp': { bg: '#607d8b', icon: <AcUnit sx={iconColor} /> },
    'Sound/Noise': { bg: '#4caf50', icon: <VolumeUp sx={iconColor} /> },
    Pressure: { bg: '#e91e63', icon: <DeviceThermostat sx={iconColor} /> }
};

/** COLLAPSIBLE PANEL STYLES */
const MainContainer = styled(Paper)(({ theme }) => ({
    position: 'relative',
    borderRadius: 8,
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    overflow: 'hidden'
}));

const HeaderBar = styled(Box)(() => ({
    backgroundColor: '#2196f3',
    color: '#fff',
    padding: '12px 16px',
    fontWeight: 600,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center'
}));

const CollapseWrapper = styled(Box)(({ collapsed }) => ({
    position: 'relative',
    maxHeight: collapsed ? '420px' : 'none',
    overflow: 'hidden',
    transition: 'max-height 0.4s ease'
}));

const FadeOverlay = styled(Box)(({ show }) => ({
    display: show ? 'block' : 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 60,
    background: 'linear-gradient(transparent, rgba(255,255,255,0.95))'
}));

/** SUBSECTION BLOCKS */
const SubSection = styled(Paper)(() => ({
    border: '1px solid #ccc',
    borderRadius: 6,
    marginBottom: 16,
    overflow: 'hidden'
}));

const SubHeader = styled(Box)(() => ({
    backgroundColor: '#2196f3',
    color: '#fff',
    padding: '8px 16px',
    fontWeight: 600,
    fontSize: '0.95rem',
    display: 'flex',
    alignItems: 'center'
}));

const SubBody = styled(Box)(() => ({
    backgroundColor: '#fff',
    padding: 16
}));

/** ENERGY HAZARD BLOCKS */
const HazardBlock = styled(Box)(() => ({
    border: '1px solid #ddd',
    borderRadius: 6,
    marginTop: 16,
    overflow: 'hidden'
}));

const HazardHeader = styled(Box)(({ bg }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: '#fff',
    backgroundColor: bg,
    padding: '6px 10px'
}));

function chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

export default function JhaStepThree() {
    const { state } = useLocation() || {};
    const { jha = null } = state || {};
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [jhaData, setJhaData] = useState(null);
    const [employeesSignatures, setEmployeesSignatures] = useState([]);
    const [signatureModalOpen, setSignatureModalOpen] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        if (!jha?.id) return;
        setLoading(true);
        const db = await initializeDB();
        const tx = db.transaction(JHA_STORE, 'readwrite');
        const store = tx.objectStore(JHA_STORE);
        const existing = await store.get(jha.id);
        if (!existing.stepThree) existing.stepThree = { employeesSignatures: [] };
        if (!existing.stepTwo) existing.stepTwo = {};
        await store.put(existing);
        setJhaData(existing);
        setEmployeesSignatures(existing.stepThree.employeesSignatures || []);
        setLoading(false);
    }

    async function saveData(updated) {
        const db = await initializeDB();
        const tx = db.transaction(JHA_STORE, 'readwrite');
        const store = tx.objectStore(JHA_STORE);
        await store.put(updated);
    }

    function handlePrev() {
        if (!jhaData) return;
        saveData(jhaData);
        navigate('/jha/step-2', { state: { jha: jhaData, isNew: false } });
    }

    function handleNext() {
        if (!isFormValid) return;
        saveData(jhaData);
        navigate('/jha/step-4', { state: { jha: jhaData, isNew: false } });
    }

    function openSignatureModal(emp) {
        setCurrentEmployee(emp);
        setSignatureModalOpen(true);
    }

    function handleSignatureSave(sig) {
        const copy = { ...jhaData };
        if (!copy.stepThree) copy.stepThree = { employeesSignatures: [] };
        const arr = [...copy.stepThree.employeesSignatures];
        const idx = arr.findIndex(e => e.employeeId === currentEmployee?.employeeId);
        if (idx >= 0) arr[idx].signature = sig;
        else {
            arr.push({
                employeeId: currentEmployee?.employeeId,
                fullName: currentEmployee?.FullName,
                signature: sig
            });
        }
        copy.stepThree.employeesSignatures = arr;
        setJhaData(copy);
        setEmployeesSignatures(arr);
        saveData(copy);
        setSignatureModalOpen(false);
    }

    function removeSignature(emp) {
        const copy = { ...jhaData };
        const arr = [...(copy.stepThree?.employeesSignatures || [])];
        const idx = arr.findIndex(e => e.employeeId === emp.employeeId);
        if (idx >= 0) arr.splice(idx, 1);
        copy.stepThree.employeesSignatures = arr;
        setJhaData(copy);
        setEmployeesSignatures(arr);
        saveData(copy);
    }

    const isFormValid = useMemo(() => {
        if (!jhaData?.stepOne?.employeesSelected?.length) return false;
        return jhaData.stepOne.employeesSelected.every(e =>
            (jhaData.stepThree?.employeesSignatures || []).some(s => s.employeeId === e.employeeId)
        );
    }, [jhaData]);

    if (!jhaData || loading) return <Box p={2}>Loading...</Box>;

    // Combine tasks + custom tasks, etc.
    const tasks = [...(jhaData.stepTwo.tasks || []), ...(jhaData.stepTwo.customTasks || [])];
    const workActivities = [...(jhaData.stepTwo.workActivities || []), ...(jhaData.stepTwo.customWorkActivities || [])];
    const energyExposures = jhaData.stepTwo.energyExposures || [];
    const sequenceText = jhaData.stepTwo.sequenceOfJobSteps;

    // For 4-column layout
    const tasksChunks = chunkArray(tasks, 4);
    const workChunks = chunkArray(workActivities, 4);

    return (
        <Box p={2}>
            <StepHeader steps={['Step 1','Step 2','Step 3','Step 4']} activeStep={2} />
            <Typography variant="h5" align="center" sx={{ mb: 1 }}>
                Step 3 - Review & Sign
            </Typography>

            <MainContainer>
                <HeaderBar>
                    <Box flex={1}>Job Hazard Analysis Details</Box>
                    <IconButton onClick={() => setCollapsed(!collapsed)} sx={{ color: '#fff' }}>
                        {collapsed ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                </HeaderBar>

                <CollapseWrapper collapsed={collapsed}>
                    <Box sx={{ p: 2 }}>
                        {/* 1) Detailed Summary of Job/Task */}
                        <SubSection sx={{ mb: 3 }}>
                            <SubHeader>
                                <Handyman sx={{ mr: 1 }} />
                                Detailed Summary of Job/Task
                            </SubHeader>
                            <SubBody>
                                {!tasks.length ? (
                                    <Typography color="text.secondary">No Tasks Selected</Typography>
                                ) : (
                                    <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                        <Typography sx={{ fontWeight: 600, mb: 1 }}>Overview of Tasks</Typography>
                                        <Table size="small" sx={{ border: '1px solid #ccc' }}>
                                            <TableBody>
                                                {tasksChunks.map((row, rowIndex) => (
                                                    <TableRow key={`tasksRow-${rowIndex}`}>
                                                        {row.map((taskVal, cellIndex) => (
                                                            <TableCell
                                                                key={`tasksCell-${cellIndex}`}
                                                                sx={{ borderRight: '1px solid #eee', width: '25%' }}
                                                            >
                                                                <Check sx={{ fontSize: 16, mr: 1, color: '#4caf50' }} />
                                                                <b>{taskVal}</b>
                                                            </TableCell>
                                                        ))}
                                                        {Array.from({ length: 4 - row.length }).map((_, fillIdx) => (
                                                            <TableCell
                                                                key={`tasksEmpty-${fillIdx}`}
                                                                sx={{ borderRight: '1px solid #eee', width: '25%' }}
                                                            />
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                )}
                            </SubBody>
                        </SubSection>

                        {/* 2) Work Activities */}
                        <SubSection sx={{ mb: 3 }}>
                            <SubHeader>
                                <BuildCircle sx={{ mr: 1 }} />
                                Work Activities
                            </SubHeader>
                            <SubBody>
                                {!workActivities.length ? (
                                    <Typography color="text.secondary">No Work Activities Selected</Typography>
                                ) : (
                                    <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                                        <Typography sx={{ fontWeight: 600, mb: 1 }}>Overview of Activities</Typography>
                                        <Table size="small" sx={{ border: '1px solid #ccc' }}>
                                            <TableBody>
                                                {workChunks.map((row, rowIndex) => (
                                                    <TableRow key={`workRow-${rowIndex}`}>
                                                        {row.map((val, cellIndex) => (
                                                            <TableCell
                                                                key={`workCell-${cellIndex}`}
                                                                sx={{ borderRight: '1px solid #eee', width: '25%' }}
                                                            >
                                                                <Check sx={{ fontSize: 16, mr: 1, color: '#4caf50' }} />
                                                                <b>{val}</b>
                                                            </TableCell>
                                                        ))}
                                                        {Array.from({ length: 4 - row.length }).map((_, fillIdx) => (
                                                            <TableCell
                                                                key={`workEmpty-${fillIdx}`}
                                                                sx={{ borderRight: '1px solid #eee', width: '25%' }}
                                                            />
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                )}
                            </SubBody>
                        </SubSection>

                        {/* 3) Energy Hazard */}
                        <SubSection sx={{ mb: 3 }}>
                            <SubHeader>
                                <ReportProblem sx={{ mr: 1 }} />
                                Energy Hazard
                            </SubHeader>
                            <SubBody>
                                {!energyExposures.length ? (
                                    <Typography color="text.secondary">No Energy Exposures Selected</Typography>
                                ) : (
                                    energyExposures.map((energyItem, idx) => {
                                        const cfg = colorMap[energyItem.value] || { bg: '#9e9e9e', icon: <ReportProblem sx={iconColor} /> };
                                        return (
                                            <HazardBlock key={idx} sx={{ mt: 2,borderColor: cfg.bg }}>
                                                {/* Energy Title */}
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, backgroundColor: cfg.bg, color: '#fff', p: 1 }}>
                                                    {cfg.icon}
                                                    <Typography sx={{ fontWeight: 600 }}>{energyItem.value}</Typography>
                                                </Box>
                                                <Box sx={{ p: 2 }}>
                                                    <Typography sx={{ color: '#555' }}>
                                                        {energyItem.description}
                                                    </Typography>
                                                    {(energyItem.hazardEnergies || []).map((haz, i) => (
                                                        <Paper
                                                            key={i}
                                                            sx={{
                                                                p: 2,
                                                                borderRadius: 2,
                                                                background: '#f9f9f9',
                                                                mt: 2
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            {/* Hazard Title */}
                                                            <Typography sx={{ fontWeight: 600, mb: 1 }}>
                                                                {haz.value}
                                                            </Typography>

                                                            {/* If no measures */}
                                                            {!haz.preventHazards?.length && (
                                                                <Typography color="text.secondary">No Preventive Measures</Typography>
                                                            )}

                                                            {/* If we have measures */}
                                                            {!!haz.preventHazards?.length && (
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: 1,
                                                                            fontWeight: 600,
                                                                            mb: 1
                                                                        }}
                                                                    >
                                                                        <ShieldMoon sx={{ fontSize: 16 }} />
                                                                        Measures
                                                                    </Typography>
                                                                    {haz.preventHazards.map((ph, k) => (
                                                                        <Box
                                                                            key={k}
                                                                            sx={{ display: 'flex', alignItems: 'center', ml: 2, mt: 0.5 }}
                                                                        >
                                                                            <Circle sx={{ fontSize: 8, mr: 1, color: '#757575' }} />
                                                                            <Typography>{ph.value}</Typography>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                        </Paper>
                                                    ))}
                                                </Box>
                                            </HazardBlock>
                                        );
                                    })
                                )}
                            </SubBody>
                        </SubSection>

                        {/* 4) Sequence of Job Steps/Tasks */}
                        <SubSection sx={{ mb: 3 }}>
                            <SubHeader>
                                <ReportProblem sx={{ mr: 1 }} />
                                Sequence of Job Steps/Tasks
                            </SubHeader>
                            <SubBody>
                                {!sequenceText ? (
                                    <Typography color="text.secondary">No text provided</Typography>
                                ) : (
                                    <Paper sx={{ mt: 1, p: 2, borderRadius: 2 }}>
                                        {sequenceText}
                                    </Paper>
                                )}
                            </SubBody>
                        </SubSection>
                    </Box>
                    <FadeOverlay show={collapsed} />
                </CollapseWrapper>

                {/* Show More / Less button at the bottom */}
                <Box display="flex" justifyContent="center" sx={{ backgroundColor: '#f5f5f5', py: 1 }}>
                    <Button variant="text" onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? 'SHOW MORE...' : 'SHOW LESS'}
                    </Button>
                </Box>
            </MainContainer>

            {/* Employee Signatures */}
            <Paper variant="outlined" sx={{ borderRadius: 2, mt: 2 }}>
                <Box
                    sx={{
                        backgroundColor: '#2196f3',
                        color: '#fff',
                        p: 2,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    Employee Signatures
                </Box>
                <Box p={2}>
                    {!jhaData.stepOne?.employeesSelected?.length && (
                        <Typography color="text.secondary">No employees</Typography>
                    )}
                    {jhaData.stepOne?.employeesSelected?.map((emp, i) => {
                        const existingSig = employeesSignatures.find(s => s.employeeId === emp.employeeId);
                        return (
                            <Box key={i} display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                <Box>
                                    <Typography>{emp.FullName}</Typography>
                                    {existingSig?.signature ? (
                                        <Box
                                            component="img"
                                            src={existingSig.signature}
                                            alt="Signature"
                                            sx={{
                                                maxHeight: 70,
                                                border: '1px solid #ccc',
                                                mt: 1,
                                                borderRadius: 1
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No signature
                                        </Typography>
                                    )}
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Button variant="contained" onClick={() => openSignatureModal(emp)}>
                                        {existingSig ? 'Change Signature' : 'Sign'}
                                    </Button>
                                    {existingSig && (
                                        <IconButton onClick={() => removeSignature(emp)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Paper>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={handlePrev}>
                    Prev
                </Button>
                <Button variant="contained" onClick={handleNext} disabled={!isFormValid}>
                    Next
                </Button>
            </Box>

            {/* Signature Modal */}
            <SignatureModal
                open={signatureModalOpen}
                onClose={() => setSignatureModalOpen(false)}
                existingSignature={
                    employeesSignatures.find(e => e.employeeId === currentEmployee?.employeeId)?.signature || ''
                }
                employeeName={currentEmployee?.FullName || ''}
                onSave={handleSignatureSave}
            />
        </Box>
    );
}
