// components/tbt/TBTDetail.jsx
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import EmployeeSelectionModal from './EmployeeSelectionModal';
import { fetchInstructionText, getCurrentWeekNumber } from '../../utils/utils';
import {
    getInstructionById,
    saveEmployeeSignature,
    updateInstruction
} from '../../services/instructionService';

import InstructionInfo from './InstructionInfo';
import UploadActions from './UploadActions';
import InstructionEmployees from './InstructionEmployees';
import SignModal from './SignModal';
import TextModal from './TextModal';

import axiosInstance from '../../axiosInstance';
import { initializeDB } from '../../config/db';
import { WEEKLY_INSTRUCTION_STORE, EMPLOYEE_STORE } from '../../config/constants';

const TBTDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [instruction, setInstruction] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [textContent, setTextContent] = useState('');
    const [openTextModal, setOpenTextModal] = useState(false);
    const [openSignModal, setOpenSignModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const sigPadRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastSentAt, setLastSentAt] = useState(false);

    // <-- Флаг загрузки (отправки)
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            try {
                const selectedInstruction = await getInstructionById(id);
                if (selectedInstruction) {
                    setLastSentAt(selectedInstruction.lastSentAt);
                    setInstruction(selectedInstruction);
                    setEmployees(selectedInstruction.signedEmployees || []);
                } else {
                    console.error('Instruction not found');
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            }
        };

        loadData();
    }, [id]);

    const selectedEmployeeName =
        employees.find(emp => emp.id === selectedEmployee)?.FullName || "Unknown Employee";

    const saveSignature = async () => {
        if (sigPadRef.current && instruction && instruction.uniqueId) {
            const signatureData = sigPadRef.current.toDataURL();
            try {
                const updatedInstruction = await saveEmployeeSignature(
                    instruction.uniqueId,
                    selectedEmployee,
                    signatureData
                );
                setInstruction(updatedInstruction);
                setEmployees(updatedInstruction.signedEmployees);
            } catch (error) {
                console.error('Error saving signature:', error);
                alert('Failed to save signature.');
            }
            setOpenSignModal(false);
        }
    };

    // -------------
    // Загружаем подписи (с блокировкой повторных кликов)
    // -------------
    const uploadSignatures = async () => {
        // Если уже идёт отправка – ничего не делаем
        if (isUploading) return;

        setIsUploading(true);
        try {
            const currentWeekNumber = getCurrentWeekNumber();
            const response = await axiosInstance.post('/upload-signatures', {
                instructionId: instruction.instructionId || null,
                instructionUserId: instruction.uniqueId || null,
                title: instruction.title || null,
                date: instruction.date || null,
                weekNumber: currentWeekNumber,
                instructionDate: new Date().toISOString(),
                instructorId: localStorage.getItem('userId') || null,
                signatures: employees.map(emp => ({
                    employeeId: emp.id || null,
                    signature: emp.signature || null,
                    signedAt: emp.signedAt || null,
                })),
            });

            if (response.status === 200) {
                const updatedInstruction = {
                    ...instruction,
                    status: 'sent',
                    lastSentAt: new Date().toLocaleString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                    })
                };
                await updateInstruction(updatedInstruction);
                setInstruction(updatedInstruction);
                setLastSentAt(updatedInstruction.lastSentAt);

                const instructionStatusChangedEvent = new CustomEvent(
                    'instructionStatusChanged',
                    { detail: { status: 'sent' } }
                );
                window.dispatchEvent(instructionStatusChangedEvent);

                alert('Data sent successfully');
                navigate('/tbt');
            }
        } catch (error) {
            console.error("Error uploading signatures:", error);
            alert("Failed to upload signatures.");
        } finally {
            // Снимаем флаг даже в случае ошибки
            setIsUploading(false);
        }
    };

    const openTextContentModal = async (language) => {
        const text = await fetchInstructionText(instruction.instructionId, language);
        if (text) {
            setTextContent(text);
            setOpenTextModal(true);
        } else {
            console.error('Text not found');
        }
    };

    const handleSaveEmployees = async (updatedEmployeeIds) => {
        try {
            const db = await initializeDB();
            const employeeTx = db.transaction(EMPLOYEE_STORE, 'readonly');
            const employeeStore = employeeTx.objectStore(EMPLOYEE_STORE);

            const existingSignedEmployees = instruction?.signedEmployees || [];

            const updatedEmployees = await Promise.all(
                updatedEmployeeIds.map(async (employeeId) => {
                    const existingEmployee = existingSignedEmployees.find(
                        emp => emp.id === employeeId
                    );
                    if (existingEmployee) {
                        return { ...existingEmployee };
                    } else {
                        const employee = await employeeStore.get(employeeId);
                        return { ...employee, signature: null, signedAt: null };
                    }
                })
            );

            const updatedInstruction = {
                ...instruction,
                signedEmployees: updatedEmployees
            };

            if (updatedEmployees.length > 0) {
                updatedInstruction.status = 'process';
            }

            const instructionsTx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
            const instructionsStore = instructionsTx.objectStore(WEEKLY_INSTRUCTION_STORE);
            await instructionsStore.put(updatedInstruction);

            setInstruction(updatedInstruction);
            setEmployees(updatedEmployees);

            const instructionStatusChangedEvent = new CustomEvent('instructionStatusChanged');
            window.dispatchEvent(instructionStatusChangedEvent);

        } catch (error) {
            console.error("Error saving updated employees:", error);
        }
    };

    const handleDeleteEmployee = async (employeeId) => {
        if (!instruction) return;

        try {
            const db = await initializeDB();
            const tx = db.transaction(WEEKLY_INSTRUCTION_STORE, 'readwrite');
            const store = tx.objectStore(WEEKLY_INSTRUCTION_STORE);
            const updatedInstruction = { ...instruction };
            updatedInstruction.signedEmployees = updatedInstruction.signedEmployees.filter(
                emp => emp.id !== employeeId
            );

            await store.put(updatedInstruction);
            setInstruction(updatedInstruction);
            setEmployees(updatedInstruction.signedEmployees);
            alert('Employee deleted successfully');
        } catch (error) {
            console.error("Error deleting employee:", error);
            alert("Failed to delete employee.");
        }
    };

    if (!instruction) {
        return <p>Loading...</p>;
    }

    return (
        <Box sx={{ p: 4 }}>
            <Link to="/tbt">
                <Button variant="contained" color="success" sx={{ my: 2 }}>
                    Back
                </Button>
            </Link>

            <InstructionInfo
                instruction={instruction}
                onViewEnglish={() => openTextContentModal('english')}
                onViewSpanish={() => openTextContentModal('spanish')}
            />

            {instruction.signedEmployees && instruction.signedEmployees.length > 0 && (
                <UploadActions
                    onUpload={uploadSignatures}
                    lastSentAt={lastSentAt}
                    status={instruction.status}
                    // Передаём флаг, чтобы в UploadActions заблокировать кнопку
                    isUploading={isUploading}
                />
            )}

            <Typography variant="h5" sx={{ mt: 3 }}>
                Employees List:
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setIsModalOpen(true)}
            >
                Edit Employees List For This TBT
            </Button>

            <InstructionEmployees
                employees={employees}
                instructionStatus={instruction.status}
                onDeleteEmployee={handleDeleteEmployee}
                onOpenSignModal={(employeeId) => {
                    setSelectedEmployee(employeeId);
                    setOpenSignModal(true);
                }}
            />

            <EmployeeSelectionModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveEmployees}
                existingEmployees={employees.map((e) => e.id)}
            />

            <TextModal
                open={openTextModal}
                onClose={() => setOpenTextModal(false)}
                textContent={textContent}
            />

            <SignModal
                open={openSignModal}
                onClose={() => setOpenSignModal(false)}
                sigPadRef={sigPadRef}
                employeeName={selectedEmployeeName}
                onAccept={saveSignature}
            />
        </Box>
    );
};

export default TBTDetail;
