// components/jha/JhaStepTwo/EvaluationSection.jsx

import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Radio
} from '@mui/material';

export default function EvaluationSection({ jhaData, onSaveEvaluation }) {
    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Evaluation Of Work Area
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell sx={{ fontWeight: 'bold', top: 0 }}>Item</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', top: 0 }} align="center">
                                    YES
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold', top: 0 }} align="center">
                                    NO
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold', top: 0 }} align="center">
                                    N/A
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jhaData.stepTwo.evaluationOfWork.map(e => (
                                <TableRow key={e.value}>
                                    <TableCell>{e.value}</TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={e.selected === 'YES'}
                                            onChange={() => onSaveEvaluation(e.value, 'YES')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={e.selected === 'NO'}
                                            onChange={() => onSaveEvaluation(e.value, 'NO')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={e.selected === 'N/A'}
                                            onChange={() => onSaveEvaluation(e.value, 'N/A')}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}
