import React from 'react';
import { Box, Typography, Modal, Paper, Grid, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';

const SSDSignModal = ({ open, onClose, sigPadRef, employeeName, onAccept }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute', top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600, maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24, borderRadius: '8px',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Signature for: <b>{employeeName}</b>
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <SignaturePad
                        ref={sigPadRef}
                        canvasProps={{
                            width: 566,
                            height: 200,
                            className: 'sigCanvas',
                        }}
                    />
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={onAccept}>
                            Accept
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default SSDSignModal;
