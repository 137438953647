// SSDGivenBySingleSelectModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { initializeDB } from '../../config/db';
import { EMPLOYEE_STORE } from '../../config/constants';

/**
 * Модалка для выбора одного сотрудника (GivenBy).
 */
export default function SSDGivenBySingleSelectModal({ open, onClose, onSave }) {
    const [rows, setRows] = useState([]);
    // Для выделения строки
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (open) {
            loadEmployees();
        }
    }, [open]);

    async function loadEmployees() {
        try {
            const db = await initializeDB();
            const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
            const store = tx.objectStore(EMPLOYEE_STORE);
            const result = await store.getAll();
            await tx.done;

            // Для DataGrid нужно поле "id"
            const rowData = result.map(item => ({
                ...item,
                id: item.employeeId,
            }));
            setRows(rowData);
            setSelectionModel([]); // сброс прежнего выбора
        } catch (error) {
            console.error('Error loading employees for GivenByModal:', error);
        }
    }

    function handleRowClick(params) {
        // Один клик = выбор строки
        setSelectionModel([params.id]);
    }

    function handleSelectionChange(newSelection) {
        setSelectionModel(newSelection);
    }

    function handleSave() {
        if (selectionModel.length > 0) {
            // берем первый выбранный ID
            const selectedId = selectionModel[0];
            const selectedObj = rows.find(r => r.id === selectedId);
            onSave(selectedObj); // <-- вызываем родительский onSave
        } else {
            // ничего не выбрано
            onSave(null);
        }
        onClose();
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: '#fff',
                    p: 3,
                    boxShadow: 24,
                    borderRadius: 1,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Select Given By
                </Typography>
                <Box sx={{ height: 400, mt: 2 }}>
                    <DataGrid
                        rows={rows}
                        columns={[
                            { field: 'FullName', headerName: 'Full Name', flex: 1 },
                            { field: 'Division', headerName: 'Division', width: 130 },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10]}
                        // Отключаем чекбоксы, включаем клик по строке
                        checkboxSelection={false}
                        disableSelectionOnClick={false}
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelectionChange}
                        onRowClick={handleRowClick}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
