import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {HashRouter} from 'react-router-dom'; // импорт BrowserRouter
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // импорт service worker registration

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HashRouter > {/* Оборачиваем App в BrowserRouter */}
            <App />
        </HashRouter >
    </React.StrictMode>
);

// Активируем service worker для работы офлайн
serviceWorkerRegistration.register(); // регистрация service worker

reportWebVitals();
