import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    TextField,
    Button,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Chip
} from '@mui/material';

export default function GeneralInformation({
                                               jhaData,
                                               jobs,
                                               conditions = [],
                                               onSaveField,
                                               onSaveMultipleFields,
                                               openEmployeeModal,
                                               openSupervisorModal
                                           }) {
    const stepTwo = jhaData.stepTwo || {};

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    General Information
                </Typography>

                {/* 1-й ряд: Person Completing JHA и Job Number */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Person Completing JHA</Typography>
                        <Box display="flex" gap={1} mt={1}>
                            <TextField
                                fullWidth
                                size="small"
                                disabled
                                value={
                                    stepTwo.personCompleting?.FullName || stepTwo.personCompleting || ''
                                }
                            />
                            <Button
                                onClick={openEmployeeModal}
                                variant="outlined"
                                size="small"       // <--- чтобы совпадала высота с TextField
                            >
                                SELECT
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Job Number</Typography>
                        <Autocomplete
                            fullWidth
                            size="small"
                            sx={{ mt: 1 }}
                            options={jobs}
                            getOptionLabel={(o) => `${o.Job} - ${o.JobName}`}
                            value={jobs.find((j) => j.Job === stepTwo.jobNumber) || null}
                            onChange={(_, newVal) => {
                                if (!newVal) {
                                    onSaveMultipleFields({
                                        jobNumber: '',
                                        jobSiteName: '',
                                        jobSiteAddress: ''
                                    });
                                } else {
                                    onSaveMultipleFields({
                                        jobNumber: newVal.Job,
                                        jobSiteName: newVal.location,
                                        jobSiteAddress: newVal.siteAddress
                                    });
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Search Job Number" />
                            )}
                        />
                    </Grid>
                </Grid>

                {/* 2-й ряд: Date, Job Site Name, Job Site Address */}
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2">Date</Typography>
                        <TextField
                            type="date"
                            size="small"
                            fullWidth
                            sx={{ mt: 1 }}
                            value={stepTwo.date || ''}
                            onChange={(e) => onSaveField('date', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2">Job Site Name</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            disabled
                            sx={{ mt: 1 }}
                            value={stepTwo.jobSiteName || ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="subtitle2">Job Site Address</Typography>
                        <TextField
                            size="small"
                            fullWidth
                            disabled
                            sx={{ mt: 1 }}
                            value={stepTwo.jobSiteAddress || ''}
                        />
                    </Grid>
                </Grid>

                {/* 3-й ряд: Supervisor и Weather Conditions, с выравниванием */}
                <Grid container spacing={2} mt={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">Job Site Supervisor</Typography>
                        <Box display="flex" gap={1} mt={1}>
                            <TextField
                                fullWidth
                                size="small"
                                disabled
                                value={stepTwo.jobSiteSupervisor?.FullName || ''}
                            />
                            <Button
                                onClick={openSupervisorModal}
                                variant="outlined"
                                size="small"   // <--- Чтобы совпадала высота с TextField
                            >
                                SELECT
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2">
                            Weather Conditions/Temperature
                        </Typography>
                        <FormControl size="small" fullWidth sx={{ mt: 1 }}>
                            <Select
                                multiple
                                value={stepTwo.weatherConditions || []}
                                onChange={(e) => {
                                    const v = Array.isArray(e.target.value)
                                        ? e.target.value
                                        : [e.target.value];
                                    onSaveField('weatherConditions', v);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((item) => (
                                            <Chip key={item} label={item} size="small" />
                                        ))}
                                    </Box>
                                )}
                            >
                                {conditions.map((cond) => (
                                    <MenuItem key={cond.value} value={cond.value}>
                                        {cond.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
