export const DB_NAME = 'dhgriffin';
export const DB_VERSION = 19;
export const EMPLOYEE_STORE = 'employees';
export const INSTRUCTION_STORE = 'instructions';
export const WEEKLY_INSTRUCTION_STORE = 'weeklyInstructions';
export const WEEK_LIST_STORE = 'weeklyList';
export const SIGNED_DOCS_STORE = 'signedDocuments';
export const TRAINING_DUE_STORE = 'trainingDue';
export const TRAINING_DUE_TEXT_STORE = 'trainingDueText';
export const TRAINING_DUE_FORM_STORE = 'trainingDueDefaultForm';
export const TRAINING_STORE = 'training';
export const MEP_FORM_STORE = 'trainingDueMepForm ';
export const FT_FORM_STORE = 'trainingDueFtForm';
export const WELDING_FORM_STORE = 'trainingDueWeldingForm';
export const COST_CODE_STORE = 'costCode';
export const GL_CODE_STORE = 'glCode';
export const CC_STORE = 'ccPhoto';
export const JOB_STORE = 'job';
export const CODE_GROUP_STORE = 'codeGroup';
export const INI_STORE = 'ini';
export const SSD_STORE = 'ssd';
export const STATUS_NEW = 'new';
export const STATUS_PROCESS = 'process';
export const STATUS_FINISH = 'finish';
export const STATUS_SEND = 'send';
export const USER_STORE = 'user';
export const JHA_STORE = 'jha';
export const ENERGY_STORE = 'energy';
export const CONDITIONS_STORE = 'conditions';
export const DOCS_PERMIT_STORE = 'docsPermit';
export const EVALUATION_OF_WORK_STORE = 'evaluationOfWork';
export const JOB_TASK_STORE = 'jobTask';
export const WORK_ACTIVITIES_STORE = 'workActivities';
export const TEMPS_STORE = 'temps';
export const URL = 'https://admin.dhgriffinapp.com/api';
//export const URL = 'http://admin.dhgriffinapp.local/api';
//export const URL = 'http://dhgriffin.local/api';