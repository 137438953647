// components/jha/JhaStepTwo/DocsPermitsSection.jsx
import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Radio,
    Box,
    TextField,
    Button
} from '@mui/material';
import { Delete } from '@mui/icons-material';

export default function DocsPermitsSection({
                                               jhaData,
                                               onSaveDocsPermits,
                                               onSaveCustomDocsPermits,
                                               onRemoveCustomDoc,
                                               otherDoc,
                                               setOtherDoc,
                                               addCustomDoc
                                           }) {
    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Docs/Permits Required
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ position: 'sticky', top: 0, backgroundColor: '#f5f5f5', zIndex: 2 }}>
                                <TableCell sx={{ fontWeight: 'bold' }}>Document/Permit</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                    Yes Req
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                    Not Rev
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">
                                    Remove
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jhaData.stepTwo.docsPermits.map(d => (
                                <TableRow key={d.value}>
                                    <TableCell>{d.value}</TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={d.required === 'Yes Req'}
                                            onChange={() => onSaveDocsPermits(d.value, 'Yes Req')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={d.required === 'Not Rev'}
                                            onChange={() => onSaveDocsPermits(d.value, 'Not Rev')}
                                        />
                                    </TableCell>
                                    <TableCell align="center" />
                                </TableRow>
                            ))}

                            {jhaData.stepTwo.customDocsPermits.map(d => (
                                <TableRow key={d.value}>
                                    <TableCell>{d.value}</TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={d.required === 'Yes Req'}
                                            onChange={() => onSaveCustomDocsPermits(d.value, 'Yes Req')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Radio
                                            checked={d.required === 'Not Rev'}
                                            onChange={() => onSaveCustomDocsPermits(d.value, 'Not Rev')}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => onRemoveCustomDoc(d.value)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display="flex" gap={1} mt={2}>
                    <TextField
                        size="small"
                        label="Other"
                        value={otherDoc}
                        onChange={e => setOtherDoc(e.target.value)}
                    />
                    <Button variant="contained" onClick={addCustomDoc}>
                        Add
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}
