// SelectEmployeesAndTempsModal.jsx

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { initializeDB } from '../../config/db';
import { EMPLOYEE_STORE, TEMPS_STORE } from '../../config/constants';

export default function SelectEmployeesAndTempsModal({
                                                         open,
                                                         onClose,
                                                         onSelect,
                                                         alreadySelected = []
                                                     }) {
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (!open) return;
        loadData();
    }, [open]);

    async function loadData() {
        const db = await initializeDB();
        const txE = db.transaction(EMPLOYEE_STORE, 'readonly');
        const txT = db.transaction(TEMPS_STORE, 'readonly');
        const eStore = txE.objectStore(EMPLOYEE_STORE);
        const tStore = txT.objectStore(TEMPS_STORE);
        const [allEmployees, allTemps] = await Promise.all([eStore.getAll(), tStore.getAll()]);
        const mappedEmployees = allEmployees.map((e, i) => {
            const name = e.FullName || e.fullName || '';
            const division = e.Division || e.division || '';
            return {
                id: `emp-${i}`,
                FullName: name,
                DivisionAgency: division,
                EmployeeType: 'employee',
                original: { FullName: name, Division: division, _type: 'employee' }
            };
        });
        const mappedTemps = allTemps.map((t, i) => {
            const name = t.FullName || t.fullName || '';
            const agency = t.Agency || t.agency || '';
            return {
                id: `temp-${i}`,
                FullName: name,
                DivisionAgency: agency,
                EmployeeType: 'temp employee',
                original: { FullName: name, Agency: agency, _type: 'temp employee' }
            };
        });
        const merged = [...mappedEmployees, ...mappedTemps];
        const existingIds = [];
        merged.forEach(item => {
            const match = alreadySelected.find(
                sel => sel.FullName === item.original.FullName && sel._type === item.original._type
            );
            if (match) existingIds.push(item.id);
        });
        setRows(merged);
        setSelectionModel(existingIds);
    }

    function handleConfirm() {
        const selected = rows.filter(r => selectionModel.includes(r.id));
        onSelect(selected.map(s => s.original));
        onClose();
    }

    const columns = [
        { field: 'FullName', headerName: 'Full Name', flex: 1 },
        { field: 'DivisionAgency', headerName: 'Division ID/Agency', flex: 1 },
        { field: 'EmployeeType', headerName: 'Type', flex: 1 }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Select Employees</DialogTitle>
            <DialogContent>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={setSelectionModel}
                        pagination
                        pageSizeOptions={[25, 50, 100]}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={handleConfirm}>
                    Select
                </Button>
            </DialogActions>
        </Dialog>
    );
}
