// components/tbt/UploadActions.jsx
import React from 'react';
import { Paper, Box, Button, Typography, CircularProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Исходная "волновая" анимация (не трогаем / не удаляем)
const waveButton = {
    position: 'relative',
    backgroundColor: '#f44336',
    color: '#fff',
    overflow: 'visible',
    zIndex: 1,
    animation: 'blinking 2s ease-in-out infinite',
    '@keyframes blinking': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.85 },
        '100%': { opacity: 1 }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%) scale(0)',
        backgroundColor: 'rgba(244,67,54, 0.5)',
        zIndex: -1,
        animation: 'wave 1.5s infinite'
    },
    '@keyframes wave': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 1
        },
        '70%': {
            transform: 'translate(-50%, -50%) scale(2.5)',
            opacity: 0
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 0
        }
    }
};

const UploadActions = ({ onUpload, lastSentAt, status, isUploading }) => {
    // Если статус "process", используем стили "волновой анимации"
    const buttonStyle = status === 'process' ? waveButton : {};

    return (
        <Paper
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px',
                bgcolor: '#ffffff',
                borderRadius: 2,
                boxShadow: 3,
                border: '1px solid #e0e0e0',
                mt: 3
            }}
        >
            <Button
                variant="contained"
                color={status === 'process' ? 'error' : 'primary'}
                onClick={onUpload}
                // Блокируем при загрузке, чтобы избежать повторных кликов
                disabled={isUploading}
                // Меняем иконку и текст, если идёт загрузка
                startIcon={
                    isUploading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <UploadIcon />
                    )
                }
                sx={{ padding: '8px 20px', ...buttonStyle }}
            >
                {isUploading ? 'Uploading...' : 'Upload Signatures'}
            </Button>

            <Box
                display="flex"
                alignItems="center"
                bgcolor="#e8f5e9"
                padding="8px 16px"
                borderRadius="8px"
                gap={1}
            >
                <AccessTimeIcon sx={{ color: '#388e3c' }} />
                <Typography variant="body1" sx={{ color: '#388e3c' }}>
                    Last Sent At:
                </Typography>
                <Typography variant="body1" sx={{ color: '#000000' }}>
                    {lastSentAt ? new Date(lastSentAt).toLocaleString('en-US') : 'Not Sent Yet'}
                </Typography>
            </Box>
        </Paper>
    );
};

export default UploadActions;
