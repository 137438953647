// JHAPage.jsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { initializeDB } from '../../config/db';
import { JHA_STORE, STATUS_FINISH, STATUS_PROCESS } from '../../config/constants';
import JHAInstructionTable from './JHAInstructionTable';

export default function JHAPage() {
    const [jhaList, setJhaList] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        setLoading(true);
        const db = await initializeDB();
        const tx = db.transaction(JHA_STORE, 'readonly');
        const store = tx.objectStore(JHA_STORE);
        const allRecords = await store.getAll();
        const filtered = allRecords.filter(item =>
            [STATUS_PROCESS, STATUS_FINISH].includes(item.status)
        );
        setJhaList(filtered);
        setLoading(false);
    }

    function handleAdd() {
        navigate('/jha/step-1', { state: { isNew: true } });
    }

    function handleSelectRecord(record) {
        navigate('/jha/step-1', { state: { isNew: false, jha: record } });
    }
    function back(){
        navigate('/');
    }

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography>Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 2 }}>
                <Button onClick={back} variant="contained" color="success">
                    Back
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Job Hazard Analysis (JHA) List
                    </Typography>
                </Box>
                <Button variant="contained" onClick={handleAdd}>
                    Add
                </Button>
            </Box>
            {jhaList.length ? (
                <JHAInstructionTable jhaData={jhaList} onSelectRecord={handleSelectRecord} />
            ) : (
                <Typography>No data</Typography>
            )}
        </Box>
    );
}
