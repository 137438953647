import {
    EMPLOYEE_STORE,
    INSTRUCTION_STORE,
    TRAINING_DUE_STORE,
    COST_CODE_STORE,
    GL_CODE_STORE,
    USER_STORE,
    CODE_GROUP_STORE,
    JOB_STORE,
    TRAINING_DUE_TEXT_STORE,
    INI_STORE,
    ENERGY_STORE,
    CONDITIONS_STORE,
    DOCS_PERMIT_STORE,
    EVALUATION_OF_WORK_STORE, JOB_TASK_STORE, WORK_ACTIVITIES_STORE, TEMPS_STORE,
} from './constants';
import { createWeeklyInstructions } from '../functions/createWeeklyInstructions';

// В каждом пункте — Store, endpoint, localStorageKey. Если нужна индивидуальная логика — добавляем syncHandler.
export const syncConfig = {
    user: {
        storeName: USER_STORE,
        endpoint: '/user',
        localStorageKey: 'lastUserSyncTime',


        async syncHandler(db, storeName, data) {

            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);

            await store.clear();

            await store.put({
                id: data.userId ?? 1,
                firstName: data.firstName ?? '',
                lastName: data.lastName ?? '',
                employeeId: data.employeeId ?? '',
                roles: data.roles ?? [],
                updatedAt: new Date().toISOString(),
            });

            await tx.done;

            // временно в Localstorage что бы оставить прошлый функционал до рефактора
            localStorage.setItem('firstName', data.firstName ?? '');
            localStorage.setItem('lastName', data.lastName ?? '');
            localStorage.setItem('userId', String(data.userId ?? ''));
            localStorage.setItem('employeeId', String(data.employeeId ?? ''));
            localStorage.setItem('roles', JSON.stringify(data.roles ?? []));


        },
    },

    employees: {
        storeName: EMPLOYEE_STORE,
        endpoint: '/employees',
        localStorageKey: 'lastEmployeeSyncTime',
    },
    instructions: {
        storeName: INSTRUCTION_STORE,
        endpoint: '/instructions',
        localStorageKey: 'lastInstructionSyncTime',
        // Свой кастомный обработчик
        async syncHandler(db, storeName, data) {
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);

            // 1. Помечаем все записи как passive
            const allInstructions = await store.getAll();
            for (const instr of allInstructions) {
                instr.status = 'passive';
                await store.put(instr);
            }

            // 2. «Умная» логика обновления
            for (const newInstr of data) {
                const existing = await store.get(newInstr.id);
                if (
                    existing &&
                    existing.TBTNo === newInstr.TBTNo &&
                    existing.WEDate === newInstr.WEDate
                ) {
                    existing.Title = newInstr.Title;
                    existing.textEnglish = newInstr.textEnglish;
                    existing.textSpanish = newInstr.textSpanish;
                    existing.status = 'active';
                    await store.put(existing);
                } else {
                    newInstr.status = 'active';
                    await store.put(newInstr);
                }
            }

            await tx.done;

            // Создаём еженедельные инструкции (как и раньше)
            await createWeeklyInstructions(db, data);
        },
    },
    trainingDueText: {
        storeName: TRAINING_DUE_TEXT_STORE,
        endpoint: '/field-training-master',
        localStorageKey: 'lastTrainingDueText',
    },
    trainingDue: {
        storeName: TRAINING_DUE_STORE,
        endpoint: '/training-due',
        localStorageKey: 'lastTrainingDueSyncTime',
    },
    costCode: {
        storeName: COST_CODE_STORE,
        endpoint: '/cost-code',
        localStorageKey: 'lastCostCodeSyncTime',
    },
    glCode: {
        storeName: GL_CODE_STORE,
        endpoint: '/gl-code',
        localStorageKey: 'lastGLCodeSyncTime',
    },
    codeGroup: {
        storeName: CODE_GROUP_STORE,
        endpoint: '/code-group',
        localStorageKey: 'lastCodeGroupSyncTime',
        async syncHandler(db, storeName, data) {

            // Подготовим массивы для объединения
            const mergedCostCodes = [];
            const mergedGlCodes = [];

            // Пробежимся по каждому элементу массива data
            for (const item of data) {
                if (Array.isArray(item.costCodes)) {
                    mergedCostCodes.push(...item.costCodes);
                }
                if (Array.isArray(item.glCodes)) {
                    mergedGlCodes.push(...item.glCodes);
                }
            }

            const tx = db.transaction(storeName, 'readwrite');
            const cgStore = tx.objectStore(storeName);

            // Очищаем store, чтобы не копить кучу старых записей
            await cgStore.clear();

            // Вставляем одну новую запись c id=1
            const record = {
                id: 1,
                costCodes: mergedCostCodes,
                glCodes: mergedGlCodes,
                updatedAt: new Date().toISOString(),
            };

            await cgStore.put(record);
            await tx.done;
        },
    },
    job: {
        storeName: JOB_STORE,
        endpoint: '/job',
        localStorageKey: 'lastJobSyncTime',
    },
    ini: {
        storeName: INI_STORE,
        endpoint: '/ini',
        localStorageKey: 'lastIniSyncTime',
    },
    energy: {
        storeName: ENERGY_STORE,
        endpoint: '/energy',
        localStorageKey: 'lastEnergySyncTime',
    },
    conditions: {
        storeName: CONDITIONS_STORE,
        endpoint: '/conditions',
        localStorageKey: 'lastConditionsSyncTime',
    },
    docsPermits: {
        storeName: DOCS_PERMIT_STORE,
        endpoint: '/docs-permits',
        localStorageKey: 'lastDocsPermitsSyncTime',
    },
    evaluationOfWork: {
        storeName: EVALUATION_OF_WORK_STORE,
        endpoint: '/evaluation-of-work',
        localStorageKey: 'lastEvaluationOfWorkSyncTime',
    },
    jobTask: {
        storeName: JOB_TASK_STORE,
        endpoint: '/job-task',
        localStorageKey: 'lastJobTaskSyncTime',
    },
    workActivities: {
        storeName: WORK_ACTIVITIES_STORE,
        endpoint: '/work-activities',
        localStorageKey: 'lastWorkActivitiesSyncTime',
    },
    temps: {
        storeName: TEMPS_STORE,
        endpoint: '/temps',
        localStorageKey: 'lastTempsSyncTime',
    }
};
