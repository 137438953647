// src/services/ftFormService.js
import { initializeDB } from '../config/db';
import {
    FT_FORM_STORE, STATUS_FINISH,
    STATUS_PROCESS,
    STATUS_SEND
} from '../config/constants';

/**
 * Получить первую Forklift-форму со статусом "process"
 */
export async function getProcessFtForm() {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readonly');
    const store = tx.objectStore(FT_FORM_STORE);

    const allForms = await store.getAll();
    await tx.done;

    return allForms.find(f => f.status === STATUS_PROCESS) || null;
}

/**
 * Сохранить/обновить Forklift-форму
 */
export async function saveFtForm(formData) {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readwrite');
    const store = tx.objectStore(FT_FORM_STORE);

    const dataToSave = {
        ...formData,
        status: formData.status || STATUS_PROCESS,
    };

    const newId = await store.put(dataToSave);
    await tx.done;

    return newId;
}

/**
 * Пометить Forklift-форму "отправленной" (status = 'send')
 */
export async function markFtFormStatus(formId, status = STATUS_FINISH  ) {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readwrite');
    const store = tx.objectStore(FT_FORM_STORE);

    const existing = await store.get(formId);
    if (!existing) {
        await tx.done;
        return;
    }

    existing.status = status;
    await store.put(existing);
    await tx.done;
}

/**
 * Получить все Forklift-формы
 */
export async function getAllFtForms() {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readonly');
    const store = tx.objectStore(FT_FORM_STORE);
    const all = await store.getAll();
    await tx.done;
    return all;
}
