// components/jha/JhaStepTwo/EnergySection.jsx

import React from 'react';
import { Card, CardContent, Typography, Grid, Checkbox, FormControlLabel, Box } from '@mui/material';
import { styled } from '@mui/system';
import {
    Bolt,
    FireExtinguisher,
    BugReport,
    ArrowDownward,
    Construction,
    Build,
    DirectionsCar,
    AcUnit,
    VolumeUp,
    DeviceThermostat,
    HelpOutline
} from '@mui/icons-material';

const iconStyle = { color: '#fff' };

const colorMap = {
    Biological: { bg: '#9e9e9e', icon: <BugReport sx={iconStyle} /> },
    'Chemical/Fire': { bg: '#ff5722', icon: <FireExtinguisher sx={iconStyle} /> },
    Electrical: { bg: '#ffc107', icon: <Bolt sx={iconStyle} /> },
    Gravity: { bg: '#3f51b5', icon: <ArrowDownward sx={iconStyle} /> },
    'Motion/Line of Fire': { bg: '#ff9800', icon: <Construction sx={iconStyle} /> },
    Mechanical: { bg: '#03a9f4', icon: <Build sx={iconStyle} /> },
    'Vehicles/Equipment': { bg: '#9e9e9e', icon: <DirectionsCar sx={iconStyle} /> },
    'Weather/Temp': { bg: '#607d8b', icon: <AcUnit sx={iconStyle} /> },
    'Sound/Noise': { bg: '#4caf50', icon: <VolumeUp sx={iconStyle} /> },
    Pressure: { bg: '#e91e63', icon: <DeviceThermostat sx={iconStyle} /> }
};

const Tile = styled(Box)(({ color }) => ({
    borderRadius: 6,
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    cursor: 'pointer',
    backgroundColor: color,
    color: '#fff',
    border: '2px solid transparent',
    transition: '0.2s',
    minWidth: 180,
    '&:hover': {
        boxShadow: '0 0 6px rgba(0,0,0,0.2)'
    }
}));

const DescriptionIconBox = styled(Box)(({ color }) => ({
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff'
}));

export default function EnergySection({ jhaData, energyList, onSave }) {
    const stepTwo = jhaData.stepTwo || {};
    const selected = stepTwo.energyExposures || [];

    const toggleItem = item => {
        const copy = { ...jhaData };
        if (!copy.stepTwo) copy.stepTwo = {};
        const arr = copy.stepTwo.energyExposures || [];
        const found = arr.find(x => x.id === item.id);
        if (found) {
            copy.stepTwo.energyExposures = arr.filter(x => x.id !== item.id);
        } else {
            copy.stepTwo.energyExposures = [...arr, item];
        }
        onSave(copy);
    };

    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography variant="h6">Energy Hazard</Typography>
                <Typography sx={{ mt: 1, mb: 2 }}>
                    Please check all energies that DHG is exposed to based on tasks that are being completed.
                </Typography>

                <Grid container spacing={2}>
                    {energyList.map(e => {
                        const isSelected = selected.some(x => x.id === e.id);
                        const cfg = colorMap[e.value] || { bg: '#9e9e9e', icon: <HelpOutline sx={{ color: '#fff' }} /> };
                        return (
                            <Grid item xs={12} sm={6} md={4} key={e.id}>
                                <Tile color={cfg.bg} onClick={() => toggleItem(e)}>
                                    <FormControlLabel
                                        labelPlacement="end"
                                        onClick={ev => ev.stopPropagation()}
                                        control={
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={ev => {
                                                    ev.stopPropagation();
                                                    toggleItem(e);
                                                }}
                                                sx={{ p: 0.5, color: '#fff' }}
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center" gap={1}>
                                                {cfg.icon}
                                                <Typography sx={{ fontWeight: 500, color: '#fff' }}>
                                                    {e.value}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Tile>
                            </Grid>
                        );
                    })}
                </Grid>

                {!!selected.length && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Selected Hazards - Descriptions:
                        </Typography>
                        <Grid container spacing={2}>
                            {selected.map(e => {
                                const bgColor = colorMap[e.value]?.bg || '#9e9e9e';
                                const iconItem = colorMap[e.value]?.icon || <HelpOutline sx={{ color: '#fff' }} />;
                                return (
                                    <Grid item xs={12} key={e.id}>
                                        <Box
                                            sx={{
                                                border: '1px solid #ccc',
                                                borderRadius: 6,
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1
                                            }}
                                        >
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <DescriptionIconBox color={bgColor}>
                                                    {iconItem}
                                                </DescriptionIconBox>
                                                <Typography sx={{ fontWeight: 600 }}>{e.value}</Typography>
                                            </Box>
                                            <Typography variant="body2" sx={{ color: '#666' }}>
                                                {e.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}
