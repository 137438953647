// src/components/trainingDue/TrainingDue.jsx

import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { useTrainingDue } from '../../../hooks/useTrainingDue';
import { formatUSDateNoTime } from "../../../utils/utils";
import { getAllMepForms } from '../../../services/mepFormService';
import { getAllFtForms } from '../../../services/ftFormService';
import { getAllWeldingForms } from '../../../services/weldingFormService';
import { getAllTrainingDueForms } from '../../../services/trainingDueDefaultFormService';
import { STATUS_PROCESS, STATUS_FINISH, STATUS_SEND } from '../../../config/constants';

export default function TrainingDue() {
    const trainingDueList = useTrainingDue();
    const [isNarrow, setIsNarrow] = useState(false);
    const [displayData, setDisplayData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsNarrow(window.innerWidth < 900);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const baseData = Array.isArray(trainingDueList)
            ? trainingDueList.filter(item => item && item.id != null)
            : [];

        (async () => {
            try {
                const [allMep, allFt, allWelding, allDefault] = await Promise.all([
                    getAllMepForms(),
                    getAllFtForms(),
                    getAllWeldingForms(),
                    getAllTrainingDueForms()
                ]);

                const mepMap = {};
                allMep.forEach(form => {
                    mepMap[form.trainingDueId] = form.status;
                });
                const ftMap = {};
                allFt.forEach(form => {
                    ftMap[form.trainingDueId] = form.status;
                });
                const weldingMap = {};
                allWelding.forEach(form => {
                    weldingMap[form.trainingDueId] = form.status;
                });
                const defaultMap = {};
                allDefault.forEach(form => {
                    defaultMap[form.id] = form.status;
                });


                const merged = baseData.map(row => {
                    const course = row.course || '';
                    let rowStatus = null;
                    if (course.includes('MEWPS - aerial/platform lifts')) {
                        rowStatus = mepMap[row.id] || null;
                    } else if (course.includes('Forklift')) {
                        rowStatus = ftMap[row.id] || null;
                    } else if (course.includes('Welding')) {
                        rowStatus = weldingMap[row.id] || null;
                    } else {
                        rowStatus = defaultMap[row.id] || null;
                    }
                    return { ...row, formStatus: rowStatus };
                });

                let filtered = merged.filter(item => item.formStatus !== STATUS_SEND);
                filtered.sort((a, b) => {
                    const topStatuses = [STATUS_PROCESS, STATUS_FINISH];
                    const aIsTop = topStatuses.includes(a.formStatus);
                    const bIsTop = topStatuses.includes(b.formStatus);
                    if (aIsTop && !bIsTop) return -1;
                    if (!aIsTop && bIsTop) return 1;
                    return (a.course || '').localeCompare(b.course || '');
                });

                setDisplayData(filtered);
            } catch (err) {
                setDisplayData(baseData);
            }
        })();
    }, [trainingDueList]);

    const columnsBase = [
        { field: 'course', headerName: 'Training Course', flex: 2 },
        { field: 'fullName', headerName: 'Employee Name', flex: 2 },
        { field: 'division', headerName: 'Division', flex: 1 },
        {
            field: 'dateDue',
            headerName: 'Training Due',
            flex: 1,
            renderCell: params => formatUSDateNoTime(params.value),
        },
        {
            field: 'formStatus',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ value }) => {
                if (value === STATUS_PROCESS) {
                    return (
                        <Chip
                            label="PROCESS"
                            color="warning"
                            variant="filled"
                            sx={{
                                fontWeight: 'bold',
                                borderRadius: '4px',
                                minWidth: '60px',
                                justifyContent: 'center'
                            }}
                            size="small"
                        />
                    );
                }
                if (!value) {
                    return (
                        <Chip
                            label="NOT STARTED"
                            color="default"
                            variant="filled"
                            sx={{
                                borderRadius: '4px',
                                minWidth: '80px',
                                justifyContent: 'center'
                            }}
                            size="small"
                        />
                    );
                }
                return (
                    <Chip
                        label={value.toUpperCase()}
                        color="info"
                        variant="filled"
                        sx={{
                            borderRadius: '4px',
                            minWidth: '60px',
                            justifyContent: 'center'
                        }}
                        size="small"
                    />
                );
            }
        }
    ];

    const columns = isNarrow ? columnsBase : columnsBase;

    const handleRowClick = params => {
        const row = params.row;
        const course = row.course || '';
        const isMep = course.includes('MEWPS - aerial/platform lifts');
        const isForklift = course.includes('Forklift');
        const isWelding = course.includes('Welding');

        if (isMep) {
            navigate('/training-due/mep', { state: { selectedTrainingDue: row } });
        } else if (isForklift) {
            navigate('/training-due/ft', { state: { selectedTrainingDue: row } });
        } else if (isWelding) {
            navigate('/training-due/welding', { state: { selectedTrainingDue: row } });
        } else {
            navigate('/training-due/default', { state: { selectedTrainingDue: row } });
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Paper elevation={3} sx={{ p: 2, width: '90%', margin: 'auto' }}>
                <Link to="/">
                    <Button variant="contained" color="success" sx={{ mb: 2 }}>
                        Back
                    </Button>
                </Link>
                <Typography variant="h4" gutterBottom>
                    Training Due
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rows={displayData}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10 }
                            }
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        disableSelectionOnClick
                        getRowId={row => row.id}
                        rowHeight={60}
                        onRowClick={handleRowClick}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #ccc',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 600,
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: '1px solid #eee',
                                display: 'flex',
                                alignItems: 'center',
                                lineHeight: '1.3',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                                backgroundColor: '#fafafa',
                            },
                            '& .MuiDataGrid-cellContent': {
                                fontSize: '0.95rem',
                            },
                        }}
                    />
                </Box>
            </Paper>
        </Box>
    );
}
