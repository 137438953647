// src/training/TrainingFormHeader.jsx

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Grid, Paper } from '@mui/material';
import SelectEmployeeModal from './SelectEmployeeModal';
import { getEmployeesFromDB } from '../../services/employeeService';

export default function TrainingFormHeader({
                                               operatorName,
                                               trainerName,
                                               date,
                                               onInputChange,
                                               onGoBack,
                                               readOnly = false,
                                               minDate = '',
                                               maxDate = ''
                                           }) {
    const [employees, setEmployees] = useState([]);
    const [operatorModalOpen, setOperatorModalOpen] = useState(false);
    const [trainerModalOpen, setTrainerModalOpen] = useState(false);

    // Загружаем сотрудников
    useEffect(() => {
        (async () => {
            const emps = await getEmployeesFromDB();
            setEmployees(emps);
        })();
    }, []);

    // Если trainerName не задан – берем userId (ваша логика)
    useEffect(() => {
        if (!trainerName && employees.length > 0) {
            const employeeId = localStorage.getItem('employeeId');
            if (employeeId) {
                const found = employees.find(emp => emp.employeeId === employeeId);
                if (found) {
                    onInputChange('trainerName', found.FullName);
                }
            }
        }
    }, [trainerName, employees, onInputChange]);

    const openOperatorModal = () => setOperatorModalOpen(true);
    const closeOperatorModal = () => setOperatorModalOpen(false);
    const openTrainerModal = () => setTrainerModalOpen(true);
    const closeTrainerModal = () => setTrainerModalOpen(false);

    const handleSelectOperator = (emp) => {
        onInputChange('operatorName', emp?.FullName || '');
        closeOperatorModal();
    };

    const handleSelectTrainer = (emp) => {
        onInputChange('trainerName', emp?.FullName || '');
        closeTrainerModal();
    };

    return (
        <Box sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <Button variant="contained" color="success" href={onGoBack} sx={{ mr: 2 }}>
                    BACK
                </Button>
            </Box>

            <Paper
                sx={{
                    p: 2,
                    backgroundColor: '#f5f9ff',
                    borderRadius: 2,
                    border: '1px solid #d8dde3',
                }}
                elevation={2}
            >
                <Grid container spacing={2}>
                    {/* Левый столбец */}
                    <Grid item xs={12} md={8}>
                        {/* Operator */}
                        <Box display="flex" alignItems="center" mb={1}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', minWidth: 130 }}>
                                Operator Name:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', mr: 2 }}>
                                {operatorName || 'Not selected'}
                            </Typography>
                            {!readOnly && (
                                <Button variant="contained" onClick={openOperatorModal}>
                                    SELECT OPERATOR
                                </Button>
                            )}
                        </Box>

                        {/* Trainer */}
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', minWidth: 130 }}>
                                Trainer/evaluator:
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', ml: 1 }}>
                                 {trainerName || 'Not selected'}
                            </Typography>
                            {!readOnly && (
                                <Button variant="contained" onClick={openTrainerModal}>
                                    SELECT TRAINER
                                </Button>
                            )}
                        </Box>
                    </Grid>

                    {/* Правый столбец: Date */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center" height="100%">
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', minWidth: 40 }}>
                                Date:
                            </Typography>
                            <TextField
                                sx={{ ml: 2, width: 150 }}
                                type="date"
                                size="small"
                                value={date || ''}
                                onChange={(e) => onInputChange('date', e.target.value)}
                                InputProps={{
                                    inputProps: {
                                        min: minDate || undefined,
                                        max: maxDate || undefined,
                                    }
                                }}
                                disabled={false}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Paper>

            {/* Модалки */}
            <SelectEmployeeModal
                open={operatorModalOpen}
                onClose={closeOperatorModal}
                employees={employees}
                onSelect={handleSelectOperator}
                title="Select Operator"
                defaultSelectedName={operatorName}
            />
            <SelectEmployeeModal
                open={trainerModalOpen}
                onClose={closeTrainerModal}
                employees={employees}
                onSelect={handleSelectTrainer}
                title="Select Trainer"
                defaultSelectedName={trainerName}
            />
        </Box>
    );
}
