// src/services/weldingFormService.js
import { initializeDB } from '../config/db';
import {
    WELDING_FORM_STORE,
    STATUS_PROCESS,
    STATUS_SEND, STATUS_FINISH
} from '../config/constants';

/**
 * Получить первую Welding-форму со статусом "process"
 */
export async function getProcessWeldingForm() {
    const db = await initializeDB();
    const tx = db.transaction(WELDING_FORM_STORE, 'readonly');
    const store = tx.objectStore(WELDING_FORM_STORE);

    const allForms = await store.getAll();
    await tx.done;

    return allForms.find(f => f.status === STATUS_PROCESS) || null;
}

/**
 * Сохранить/обновить Welding-форму
 */
export async function saveWeldingForm(formData) {
    const db = await initializeDB();
    const tx = db.transaction(WELDING_FORM_STORE, 'readwrite');
    const store = tx.objectStore(WELDING_FORM_STORE);

    const dataToSave = {
        ...formData,
        status: formData.status || STATUS_PROCESS,
    };

    const newId = await store.put(dataToSave);
    await tx.done;

    return newId;
}

/**
 * Пометить Welding-форму "отправленной" (status = 'send')
 */
export async function markWeldingFormStatus(formId, status = STATUS_FINISH) {
    const db = await initializeDB();
    const tx = db.transaction(WELDING_FORM_STORE, 'readwrite');
    const store = tx.objectStore(WELDING_FORM_STORE);

    const existing = await store.get(formId);
    if (!existing) {
        await tx.done;
        return;
    }

    existing.status = status;
    await store.put(existing);
    await tx.done;
}

/**
 * Получить все Welding-формы
 */
export async function getAllWeldingForms() {
    const db = await initializeDB();
    const tx = db.transaction(WELDING_FORM_STORE, 'readonly');
    const store = tx.objectStore(WELDING_FORM_STORE);
    const all = await store.getAll();
    await tx.done;
    return all;
}
