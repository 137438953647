// src/components/trainingDue/TrainingDueEmployees.jsx
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

export default function TrainingDueEmployees({
                                                 employees,
                                                 recordStatus,
                                                 onDeleteEmployee,
                                                 onOpenSignModal
                                             }) {
    return (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell align="center">Signature</TableCell>
                        <TableCell align="center">Date Signed</TableCell>
                        {recordStatus !== 'finished' && (
                            <TableCell align="center">Action</TableCell>
                        )}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {employees.map((employee) => (
                        <TableRow key={employee.id}>
                            <TableCell>{employee.FullName}</TableCell>
                            <TableCell align="center">
                                {employee.signature ? (
                                    <img src={employee.signature} alt="Signature" width="100" />
                                ) : (
                                    'No Signature'
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {employee.signedAt
                                    ? new Date(employee.signedAt).toLocaleDateString('en-US')
                                    : 'Not Signed'}
                            </TableCell>
                            {recordStatus !== 'finished' && (
                                <TableCell align="center">
                                    <Button
                                        variant="outlined"
                                        onClick={() => onOpenSignModal(employee.id)}
                                    >
                                        Sign
                                    </Button>
                                    {/*<Button*/}
                                    {/*    sx={{ mx: 2 }}*/}
                                    {/*    color="error"*/}
                                    {/*    variant="outlined"*/}
                                    {/*    onClick={() => onDeleteEmployee(employee.id)}*/}
                                    {/*>*/}
                                    {/*    Delete*/}
                                    {/*</Button>*/}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
