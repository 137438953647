// src/components/training/ft/FtForm.jsx

import React, {useEffect, useState, useCallback, useRef} from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';

import TrainingFormHeader from '../../TrainingFormHeader';
import TrainingFormSection from '../../TrainingFormSection';
import SignatureModal from '../../SignatureModal';

import {
    saveFtForm,
    markFtFormStatus,
    getAllFtForms
} from '../../../../services/ftFormService';

import {
    STATUS_FINISH,
    STATUS_PROCESS,
    STATUS_SEND
} from '../../../../config/constants';

import axiosInstance from '../../../../axiosInstance';

/** "Волновая" анимация для кнопки Send.
 * Показывается, если статус PROCESS, кнопка не disabled и не идёт загрузка.
 */
const waveButtonStyle = {
    position: 'relative',
    backgroundColor: '#f44336',
    color: '#fff',
    overflow: 'visible',
    zIndex: 1,
    animation: 'blinking 2s ease-in-out infinite',
    '@keyframes blinking': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.85 },
        '100%': { opacity: 1 }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%) scale(0)',
        backgroundColor: 'rgba(244,67,54, 0.5)',
        zIndex: -1,
        animation: 'wave 1.5s infinite'
    },
    '@keyframes wave': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 1
        },
        '70%': {
            transform: 'translate(-50%, -50%) scale(2.5)',
            opacity: 0
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 0
        }
    }
};

export default function FtForm() {
    const navigate = useNavigate();
    const { state } = useLocation() || {};
    const selectedTrainingDue = state?.selectedTrainingDue || null;
    const trainingDueIdFromRoute = selectedTrainingDue?.id || state?.trainingDueId || null;

    const sigPadRef = useRef(null);

    const [isUploading, setIsUploading] = useState(false);

    const defaultFormData = {
        operatorName: selectedTrainingDue?.fullName || '',
        trainerName: '',
        date: new Date().toISOString().split('T')[0],
        course: selectedTrainingDue?.course || '',
        sendBy: localStorage.getItem('userId'),
        // Раздел 7
        section7: {
            clutch: false,
            tiltControl: false,
            attachmentControl: false,
            serviceBrake: false,
            inchingControl: false,
            liftControl: false,
            steeringTechniques: false,
            otherEnabled: false,
            other: '',
        },

        // Раздел 8
        section8: {
            within25Feet: false,
            over25FeetNotInView: false,
            positionControlNotInView: false,
            useChocksOnIncline: false,
        },

        // Раздел 9
        section9: {
            properMounting: false,
            seatBelts: false,
            directionOfTravel: false,
            carryingForksLowered: false,
            appropriateSpeeds: false,
            carryingLoadUp: false,
            carryingLoadDown: false,
            adjustmentWetSurface: false,
            cornering: false,
            soundingHorn: false,
            otherEnabled: false,
            other: '',
        },

        // Раздел 10
        section10: {
            properBalancing: false,
            properSize: false,
            properCapacity: false,
            loadCradledBack: false,
            otherEnabled: false,
            other: '',
        },

        // Раздел 11
        section11: {
            maintainingProper: false,
            centeringLoads: false,
            HandlingLoad: false,
            smoothStartingBraking: false,
            travelingThreeForkLengths: false,
            otherEnabled: false,
            other: '',
        },

        // Раздел 12
        section12: {
            stackingSquarely: false,
            depositingLoadSafely: false,
            properHeightTieredLoad: false,
            properlyDepositing: false,
            removingLoadTravel: false,
            usingProper: false,
            removingLoadRack: false,
            otherEnabled: false,
            other: '',
        },

        // Раздел 13
        section13: {
            demonstratesParking: false,
            demonstratesLoadPlacement: false,
            awareDangerousHazards: false,
            areasNotPlaceLoads: false,
            awareFireEquipment: false,
            awareEmployees: false,
            awareAisles: false,
            awareExits: false,
            otherEnabled: false,
            other: '',
        },

        section14: {
            success: false,
        },

        // Остальное
        completed: false,
        signature: null,
        status: STATUS_PROCESS,
        trainingDueId: null,
    };

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSignatureModal, setShowSignatureModal] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const allForms = await getAllFtForms();
                const activeForms = allForms.filter(f => f.status !== STATUS_SEND);

                let foundForm = null;

                if (trainingDueIdFromRoute) {
                    foundForm = activeForms.find(f => f.trainingDueId === trainingDueIdFromRoute);
                    if (!foundForm) {
                        foundForm = {
                            ...defaultFormData,
                            trainingDueId: trainingDueIdFromRoute
                        };
                    }
                }

                setFormData(foundForm);
            } catch (err) {
                console.error('Error loading forklift forms:', err);
                setFormData(defaultFormData);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingDueIdFromRoute]);

    const autoSaveForm = useCallback(async (updatedForm) => {
        try {
            const formToSave = {
                ...updatedForm,
                trainingDueId: updatedForm.trainingDueId || trainingDueIdFromRoute || null,
            };
            const newId = await saveFtForm(formToSave);
            setFormData(prev => (prev ? { ...prev, id: newId } : prev));
        } catch (err) {
            console.error('Auto-save forklift form error:', err);
        }
    }, [trainingDueIdFromRoute]);

    const handleUpdateFormData = (updated) => {
        if (updated.status !== STATUS_FINISH) {
            updated.status = STATUS_PROCESS;
        }
        setFormData(updated);
        autoSaveForm(updated);
    };

    const handleInputChange = (key, value) => {
        if (!formData) return;
        const newData = { ...formData, [key]: value };
        handleUpdateFormData(newData);
    };

    const handleFieldChange = (section, key, value) => {
        if (!formData) return;
        const newSectionData = { ...formData[section], [key]: value };
        if (key === 'otherEnabled' && !value) {
            newSectionData.other = '';
        }
        const newData = { ...formData, [section]: newSectionData };
        handleUpdateFormData(newData);
    };

    /**
     * Функция отправки.
     * Принимает необязательный "overrides", чтобы использовать АКТУАЛЬНЫЕ данные (например, после подписи).
     */
    const handleSend = useCallback(
        async (overrides) => {
            const currentData = overrides || formData;
            if (!currentData) return;

            // Проверки
            if (!currentData.operatorName) {
                alert("Please select an operator name before sending.");
                return;
            }
            if (!currentData.trainerName) {
                alert("Please select a trainer name before sending.");
                return;
            }
            if (!currentData.section14.success) {
                alert("The maneuvering practice drills have not been successfully completed");
                return;
            }
            if (!currentData.signature) {
                alert("Signature is required to proceed.");
                return;
            }

            // Начинаем загрузку
            setIsUploading(true);

            try {
                const dataToSend = {
                    ...currentData,
                    formType: 'ft',
                };

                await axiosInstance.post('/training/form/save', dataToSend);

                if (currentData.id) {
                    await markFtFormStatus(currentData.id, STATUS_SEND);
                }
                alert('Form sent successfully.');
                navigate('/training-due');
            } catch (err) {
                console.error('Error sending forklift form', err);
                if (currentData.id) {
                    await markFtFormStatus(currentData.id, STATUS_FINISH);
                    alert('Data was not saved but save locally');
                }
                navigate('/training-due');
            } finally {
                setIsUploading(false);
            }
        },
        [formData, navigate]
    );

    /**
     * Сохранение подписи. Если всё заполнено — сразу отправляем.
     */
    const handleSignatureSaved = useCallback(
        async (base64Signature) => {
            if (!formData) return;
            let newStatus = STATUS_PROCESS;
            if (base64Signature) {
                newStatus = STATUS_FINISH;
            }
            const updated = {
                ...formData,
                signature: base64Signature || null,
                status: newStatus
            };

            setFormData(updated);
            await autoSaveForm(updated);
            setShowSignatureModal(false);

            // Если всё заполнено => сразу отправляем
            if (
                updated.operatorName &&
                updated.trainerName &&
                updated.section14.success &&
                updated.signature
            ) {
                handleSend(updated);
            }
        },
        [formData, autoSaveForm, handleSend]
    );

    if (loading || !formData) {
        return <Typography>Loading forklift form...</Typography>;
    }

    const isButtonDisabled =
        isUploading ||
        formData.status === STATUS_SEND ||
        !formData.section14.success ||
        !formData.signature ||
        !formData.operatorName ||
        !formData.trainerName;


    const isWaveActive =
        formData.status === STATUS_PROCESS &&
        !isButtonDisabled &&
        !isUploading;

    const sendButtonStyle = isWaveActive ? waveButtonStyle : {};

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                FORKLIFT PROFICIENCY / EVALUATION FORM
            </Typography>

            {/* ШАПКА */}
            <TrainingFormHeader
                onGoBack={'#/training-due'}
                operatorName={formData.operatorName}
                trainerName={formData.trainerName}
                date={formData.date}
                onInputChange={handleInputChange}
                readOnly={!!selectedTrainingDue}
                maxDate={''}
                minDate={''}
            />

            <TrainingFormSection
                title="Ability to demonstrate and explain proper use of controls"
                fields={[
                    { key: 'clutch', label: 'Clutch', type: 'checkbox' },
                    { key: 'tiltControl', label: 'Tilt control', type: 'checkbox' },
                    { key: 'attachmentControl', label: 'Attachment control', type: 'checkbox' },
                    { key: 'serviceBrake', label: 'Service brake and parking brake', type: 'checkbox' },
                    { key: 'inchingControl', label: 'Inching control (Automatic transmission)', type: 'checkbox' },
                    { key: 'liftControl', label: 'Lift Control', type: 'checkbox' },
                    { key: 'steeringTechniques', label: 'Steering techniques for type of machine', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section7}
                onFieldChange={(key, value) => handleFieldChange('section7', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'within25Feet', label: 'Proper positioning of controls when within 25 feet from truck and in view.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'over25FeetNotInView', label: 'Proper positioning of controls when over 25 feet from truck and not in view.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'positionControlNotInView', label: 'Proper position of control when not in view regardless of distance.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=''
                fields={[
                    { key: 'useChocksOnIncline', label: 'Use of chocks when parked on an incline.', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 9 */}
            <TrainingFormSection
                title="Demonstration of proper forklift operation"
                fields={[
                    { key: 'properMounting', label: 'Proper mounting and dismounting', type: 'checkbox' },
                    { key: 'seatBelts', label: 'Use of seat belts', type: 'checkbox' },
                    { key: 'directionOfTravel', label: 'Looking in the direction of travel', type: 'checkbox' },
                    { key: 'carryingForksLowered', label: 'Carrying forks in lowered position', type: 'checkbox' },
                    { key: 'appropriateSpeeds', label: 'Appropriate speeds', type: 'checkbox' },
                    { key: 'carryingLoadUp', label: 'Carrying load up incline with load pointing uphill.', type: 'checkbox' },
                    { key: 'carryingLoadDown', label: 'Carrying load down incline with load pointing uphill.', type: 'checkbox' },
                    { key: 'adjustmentWetSurface', label: 'Proper adjustment to wet or uneven driving surface', type: 'checkbox' },
                    { key: 'cornering', label: 'Cornering', type: 'checkbox' },
                    { key: 'soundingHorn', label: 'Sounding horn at corners/intersections', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section9}
                onFieldChange={(key, value) => handleFieldChange('section9', key, value)}
            />

            {/* Section 10 */}
            <TrainingFormSection
                title="Loading"
                fields={[
                    { key: 'properBalancing', label: 'Proper balancing of load', type: 'checkbox' },
                    { key: 'properSize', label: 'Proper size load for safe handling and visibility', type: 'checkbox' },
                    { key: 'properCapacity', label: 'Proper capacity for truck size and type', type: 'checkbox' },
                    { key: 'loadCradledBack', label: 'Load cradled back against backrest', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section10}
                onFieldChange={(key, value) => handleFieldChange('section10', key, value)}
            />

            {/* Section 11 */}
            <TrainingFormSection
                title="Driving with load"
                fields={[
                    { key: 'maintainingProper', label: 'Maintaining proper speed for load size', type: 'checkbox' },
                    { key: 'centeringLoads', label: 'Centering loads on forks', type: 'checkbox' },
                    { key: 'HandlingLoad', label: 'Handling load to prevent harm to cargo.', type: 'checkbox' },
                    { key: 'smoothStartingBraking', label: 'Performing smooth starting and braking', type: 'checkbox' },
                    { key: 'travelingThreeForkLengths', label: 'Traveling at least three fork truck lengths behind other trucks', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section11}
                onFieldChange={(key, value) => handleFieldChange('section11', key, value)}
            />

            {/* Section 12 */}
            <TrainingFormSection
                title="Unloading and Stacking"
                fields={[
                    { key: 'stackingSquarely', label: 'Stacking squarely and straight', type: 'checkbox' },
                    { key: 'depositingLoadSafely', label: 'Depositing load safely', type: 'checkbox' },
                    { key: 'properHeightTieredLoad', label: 'Proper height of tiered load', type: 'checkbox' },
                    { key: 'properlyDepositing', label: 'Properly depositing of load on rack', type: 'checkbox' },
                    { key: 'removingLoadTravel', label: 'Removing load & lowering to safe level before starting to travel', type: 'checkbox' },
                    { key: 'usingProper', label: 'Using proper fork spread when picking load.', type: 'checkbox' },
                    { key: 'removingLoadRack', label: 'Properly removing load from rack', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section12}
                onFieldChange={(key, value) => handleFieldChange('section12', key, value)}
            />

            {/* Section 13 */}
            <TrainingFormSection
                title="Workplace conditions"
                fields={[
                    { key: 'demonstratesParking', label: 'Demonstrates proper forklift parking', type: 'checkbox' },
                    { key: 'demonstratesLoadPlacement', label: 'Demonstrates proper load placement', type: 'checkbox' },
                    { key: 'awareDangerousHazards', label: 'Aware of dangerous or hazardous areas', type: 'checkbox' },
                    { key: 'areasNotPlaceLoads', label: 'Aware of areas not to place loads', type: 'checkbox' },
                    { key: 'awareFireEquipment', label: 'Aware of fire equipment & appropriate eyewash & 1st aid facilities', type: 'checkbox' },
                    { key: 'awareEmployees', label: 'Aware of other employees', type: 'checkbox' },
                    { key: 'awareAisles', label: 'Aware of aisles for egress', type: 'checkbox' },
                    { key: 'awareExits', label: 'Aware of exits', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section13}
                onFieldChange={(key, value) => handleFieldChange('section13', key, value)}
            />

            {/* Signature */}
            <Box
                sx={{
                    mt: 3,
                    p: 2,
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9'
                }}
            >
                <TrainingFormSection
                    title=""
                    fields={[
                        { key: 'success', label: 'Successfully completed maneuvering practice drills.', type: 'checkboxLeft' }
                    ]}
                    values={formData.section14}
                    onFieldChange={(key, value) => handleFieldChange('section14', key, value)}
                />

                {formData.signature ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                        <img
                            src={formData.signature}
                            alt="Signature"
                            style={{ maxWidth: '200px', border: '1px solid #aaa' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                            disabled={
                                isUploading ||
                                formData.status === STATUS_SEND ||
                                !formData.section14.success
                            }
                        >
                            Change Signature
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                            disabled={
                                isUploading ||
                                formData.status === STATUS_SEND ||
                                !formData.section14.success
                            }
                        >
                            Add Signature
                        </Button>
                    </Box>
                )}
            </Box>

            {/* Кнопка SEND */}
            <Box sx={{ textAlign: 'right', mt: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSend()}
                    disabled={isButtonDisabled}
                    sx={{
                        ...sendButtonStyle,
                        minWidth: '120px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {isUploading ? (
                        <>
                            <CircularProgress
                                size={24}
                                sx={{ color: '#fff', mr: 1 }}
                            />
                            Uploading...
                        </>
                    ) : (
                        'Send'
                    )}
                </Button>
            </Box>

            {showSignatureModal && (
                <SignatureModal
                    sigPadRef={sigPadRef}
                    existingSignature={formData.signature}
                    onClose={() => setShowSignatureModal(false)}
                    onSave={handleSignatureSaved}
                />
            )}
        </Box>
    );
}
