// components/jha/JhaStepTwo/WorkActivitiesSection.jsx
import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
    IconButton,
    TextField,
    Button,
    Box
} from '@mui/material';
import { Delete } from '@mui/icons-material';

export default function WorkActivitiesSection({
                                                  jhaData,
                                                  workActivitiesList,
                                                  onToggleWorkActivity,
                                                  onRemoveCustomWorkActivity,
                                                  otherWorkActivity,
                                                  setOtherWorkActivity,
                                                  addCustomWorkActivity
                                              }) {
    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Work Activities
                </Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {workActivitiesList.map(w => (
                        <Grid item xs={12} sm={6} md={4} key={w.value}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={jhaData.stepTwo.workActivities.includes(w.value)}
                                        onChange={() => onToggleWorkActivity(w.value)}
                                    />
                                }
                                label={w.value}
                            />
                        </Grid>
                    ))}

                    {(jhaData.stepTwo.customWorkActivities || []).map(wc => (
                        <Grid item xs={12} sm={6} md={4} key={wc}>
                            <Box display="flex" alignItems="center">
                                <FormControlLabel control={<Checkbox checked />} label={wc} />
                                <IconButton size="small" onClick={() => onRemoveCustomWorkActivity(wc)}>
                                    <Delete fontSize="small" />
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Box display="flex" gap={1} mt={2}>
                    <TextField
                        label="Other"
                        size="small"
                        value={otherWorkActivity}
                        onChange={e => setOtherWorkActivity(e.target.value)}
                    />
                    <Button variant="contained" onClick={addCustomWorkActivity}>
                        Add
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}
