// components/jha/SignatureModal.jsx
import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';

export default function SignatureModal({
                                           open,
                                           onClose,
                                           existingSignature = '',
                                           employeeName = '',
                                           onSave
                                       }) {
    const containerRef = useRef(null);
    const sigPadRef = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(600);

    useEffect(() => {
        if (!open) return;
        const handleResize = () => {
            if (containerRef.current) {
                const w = containerRef.current.offsetWidth;
                setCanvasWidth(w > 600 ? 600 : w);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [open]);

    useEffect(() => {
        if (!open || !sigPadRef.current) return;
        if (existingSignature) {
            sigPadRef.current.fromDataURL(existingSignature, {
                ratio: 1,
                width: canvasWidth,
                height: 200
            });
        } else {
            sigPadRef.current.clear();
        }
    }, [open, existingSignature, canvasWidth]);

    if (!open) return null;

    const clearSignature = () => {
        if (sigPadRef.current) sigPadRef.current.clear();
    };

    const acceptSignature = () => {
        if (!sigPadRef.current) return;
        if (sigPadRef.current.isEmpty()) {
            onSave('');
            return;
        }
        const base64 = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
        onSave(base64);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                maxWidth: 600,
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                p: 3,
                boxShadow: 24,
                borderRadius: '8px',
                zIndex: 9999
            }}
        >
            <Typography variant="h6" gutterBottom>
                Supervisor Pre-Shift Signature
            </Typography>
            {employeeName && (
                <Typography variant="subtitle2" gutterBottom>
                    ({employeeName})
                </Typography>
            )}
            <Box
                ref={containerRef}
                sx={{
                    mt: 1,
                    mb: 2,
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    overflow: 'hidden'
                }}
            >
                <SignaturePad
                    ref={sigPadRef}
                    canvasProps={{
                        width: canvasWidth,
                        height: 200,
                        style: { display: 'block' }
                    }}
                />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button variant="outlined" onClick={clearSignature} fullWidth>
                        Clear
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={acceptSignature} fullWidth>
                        Accept
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" color="inherit" onClick={onClose} fullWidth>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
