// src/training/mep/MepForm.jsx

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import TrainingFormHeader from '../../TrainingFormHeader';
import TrainingFormSection from '../../TrainingFormSection';

import {
    saveMepForm,
    markMepFormStatus,
    getAllForms,
} from '../../../../services/mepFormService';

import {
    STATUS_FINISH,
    STATUS_PROCESS,
    STATUS_SEND,
} from '../../../../config/constants';

import SignatureModal from '../../SignatureModal';
import axiosInstance from '../../../../axiosInstance';

/** Анимация "волны" для кнопки. Отключается, если кнопка disabled или идёт загрузка. */
const waveButtonStyle = {
    position: 'relative',
    backgroundColor: '#f44336',
    color: '#fff',
    overflow: 'visible',
    zIndex: 1,
    animation: 'blinking 2s ease-in-out infinite',
    '@keyframes blinking': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.85 },
        '100%': { opacity: 1 }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%, -50%) scale(0)',
        backgroundColor: 'rgba(244,67,54, 0.5)',
        zIndex: -1,
        animation: 'wave 1.5s infinite'
    },
    '@keyframes wave': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 1
        },
        '70%': {
            transform: 'translate(-50%, -50%) scale(2.5)',
            opacity: 0
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 0
        }
    }
};

export default function MepForm() {
    const navigate = useNavigate();
    const { state } = useLocation() || {};

    const selectedTrainingDue = state?.selectedTrainingDue || null;
    const trainingDueIdFromRoute = selectedTrainingDue?.id || state?.trainingDueId || null;

    const sigPadRef = useRef(null);

    // «Идёт ли отправка» — чтобы блокировать кнопку и показывать анимацию загрузки
    const [isUploading, setIsUploading] = useState(false);

    const defaultFormData = {
        operatorName: selectedTrainingDue?.fullName || '',
        trainerName: '',
        date: new Date().toISOString().split('T')[0],
        course: selectedTrainingDue?.course || '',
        sendBy: localStorage.getItem('userId'),
        section7: {
            raiseAndLower: false,
            travelForward: false,
            rotateLeftRight: false,
            moveBoomInOut: false,
            rotateBasket: false,
            useArticulatingControls: false,
            useOutriggers: false,
            otherEnabled: false,
            other: '',
        },
        section8: {
            nearPowerLines: false,
            fallTipHazards: false,
            controlNotInView: false,
            emergencyOperation: false,
            maxSideForce: false,
        },
        section9: {
            properMounting: false,
            directionOfTravel: false,
            weightCapacity: false,
            appropriateSpeeds: false,
            unlevelGround: false,
            properSurface: false,
            cornering: false,
            SoundingIntersections: false,
        },
        section10: {
            properParking: false,
            hazardousAreas: false,
            awareOfEmployees: false,
            otherEnabled: false,
            other: ''
        },
        section11: {
            success: false,
        },
        completed: false,
        signature: null,
        status: STATUS_PROCESS,
        trainingDueId: null, // связка с TrainingDue
    };

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSignatureModal, setShowSignatureModal] = useState(false);

    /**
     * Загрузка/создание MEP-формы из IndexedDB
     */
    useEffect(() => {
        (async () => {
            try {
                const allForms = await getAllForms();
                // Берём формы, не отправленные на сервер
                const activeForms = allForms.filter(f => f.status !== STATUS_SEND);

                let foundForm = null;

                if (trainingDueIdFromRoute) {
                    // Ищем форму, связанную с trainingDueId
                    foundForm = activeForms.find(f => f.trainingDueId === trainingDueIdFromRoute);
                    // Если не нашли, создаём новую
                    if (!foundForm) {
                        foundForm = {
                            ...defaultFormData,
                            trainingDueId: trainingDueIdFromRoute
                        };
                    }
                } else {
                    // Если ID не передали, всегда создаём новую
                    foundForm = { ...defaultFormData };
                }

                setFormData(foundForm);
            } catch (err) {
                console.error('Error loading MEP forms:', err);
                setFormData(defaultFormData);
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingDueIdFromRoute]);

    /**
     * Автосохранение формы в IndexedDB
     */
    const autoSaveForm = useCallback(async (updatedForm) => {
        try {
            const formToSave = {
                ...updatedForm,
                trainingDueId: updatedForm.trainingDueId || trainingDueIdFromRoute || null,
            };
            const newId = await saveMepForm(formToSave);
            setFormData(prev => (prev ? { ...prev, id: newId } : prev));
        } catch (error) {
            console.error('Auto-save error:', error);
        }
    }, [trainingDueIdFromRoute]);

    /**
     * Общая функция обновления стейта + автосохранения.
     * Если статус не FINISH => ставим PROCESS
     */
    const handleUpdateFormData = (updated) => {
        if (updated.status !== STATUS_FINISH) {
            updated.status = STATUS_PROCESS;
        }
        setFormData(updated);
        autoSaveForm(updated);
    };

    /**
     * Изменение полей "шапки" формы (оператор, тренер, дата)
     */
    const handleInputChange = (key, value) => {
        if (!formData) return;
        const newData = { ...formData, [key]: value };
        handleUpdateFormData(newData);
    };

    /**
     * Изменение полей внутри секций
     */
    const handleFieldChange = (section, key, value) => {
        if (!formData) return;
        const newSectionData = { ...formData[section], [key]: value };
        if (key === 'otherEnabled' && value === false) {
            newSectionData.other = '';
        }
        const newData = { ...formData, [section]: newSectionData };
        handleUpdateFormData(newData);
    };

    /**
     * Функция отправки данных на сервер.
     * Принимает необязательный аргумент "overrides", чтобы отправить АКТУАЛЬНЫЕ данные.
     */
    const handleSend = useCallback(
        async (overrides) => {
            // Если передан аргумент — берём его; иначе из стейта
            const currentForm = overrides || formData;
            if (!currentForm) return;

            // Проверки
            if (!currentForm.operatorName) {
                alert('Please select an operator name before sending.');
                return;
            }
            if (!currentForm.trainerName) {
                alert('Please select a trainer name before sending.');
                return;
            }
            if (!currentForm.section11?.success) {
                alert('The maneuvering practice drills have not been successfully completed.');
                return;
            }
            if (!currentForm.signature) {
                alert('Signature is required to proceed.');
                return;
            }

            // Ставим "идёт загрузка"
            setIsUploading(true);

            try {
                const dataToSend = {
                    ...currentForm,
                    formType: 'mep',
                };

                await axiosInstance.post('/training/form/save', dataToSend);

                // Если есть ID, ставим статус SEND
                if (currentForm.id) {
                    await markMepFormStatus(currentForm.id, STATUS_SEND);
                }
                navigate('/training-due');
                alert('Data sent successfully.');
            } catch (err) {
                console.error(err);

                // Ставим статус FINISH (локальное сохранение) при ошибке
                if (currentForm.id) {
                    await markMepFormStatus(currentForm.id, STATUS_FINISH);
                    alert('Data was not saved but save locally');
                }
                navigate('/training-due');
            } finally {
                // Снимаем "идёт загрузка"
                setIsUploading(false);
            }
        },
        [formData, navigate]
    );

    /**
     * Сохранение подписи.
     * После того, как мы записали подпись в локальный стейт + IndexedDB,
     * если всё остальное тоже заполнено, сразу отправляем (handleSend) с переданными свежими данными.
     */
    const handleSignatureSaved = useCallback(
        async (base64Signature) => {
            if (!formData) return;

            // Формируем обновлённую копию данных
            const updated = {
                ...formData,
                signature: base64Signature || null,
                // Если подпись есть => временно ставим FINISH, чтобы отличать от PROCESS
                status: base64Signature ? STATUS_FINISH : STATUS_PROCESS
            };

            // Ставим локально и автосохраняем
            setFormData(updated);
            await autoSaveForm(updated);

            // Закрываем окно подписи
            setShowSignatureModal(false);


            if (
                updated.operatorName &&
                updated.trainerName &&
                updated.section11.success &&
                updated.signature
            ) {
                // Передаём актуальные данные, чтобы подпись не потерялась
                handleSend(updated);
            }
        },
        [formData, handleSend, autoSaveForm]
    );

    if (loading || !formData) {
        return <Typography>Loading...</Typography>;
    }

    /**
     * Определяем, отключена ли кнопка "Send"
     */
    const isButtonDisabled =
        isUploading ||
        formData.status === STATUS_SEND ||
        !formData.section11?.success ||
        !formData.signature;

    /**
     * Если статус PROCESS и не идёт загрузка, и кнопка не отключена — показываем "волну"
     */
    const isWaveActive =
        formData.status === STATUS_PROCESS &&
        !isUploading &&
        !isButtonDisabled;

    // Итоговые стили для кнопки
    const sendButtonStyle = isWaveActive ? waveButtonStyle : {};

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                MEWP EQUIPMENT PROFICIENCY / EVALUATION FORM
            </Typography>

            {/* Шапка формы */}
            <TrainingFormHeader
                onGoBack={'#/training-due'}
                operatorName={formData.operatorName}
                trainerName={formData.trainerName}
                date={formData.date}
                onInputChange={handleInputChange}
                readOnly={!!selectedTrainingDue}
                maxDate={''}
                minDate={''}
            />

            {/* Section 7 */}
            <TrainingFormSection
                title="Ability to demonstrate and explain proper use of controls:"
                fields={[
                    { key: 'raiseAndLower', label: 'Raise & Lower', type: 'checkbox' },
                    { key: 'travelForward', label: 'Travel forward & Backwards while steering', type: 'checkbox' },
                    { key: 'rotateLeftRight', label: 'Rotate left & right', type: 'checkbox' },
                    { key: 'moveBoomInOut', label: 'Move boom in & out', type: 'checkbox' },
                    { key: 'rotateBasket', label: 'Rotate Basket', type: 'checkbox' },
                    { key: 'useArticulatingControls', label: 'Use Articulating Controls', type: 'checkbox' },
                    { key: 'useOutriggers', label: 'Use Outriggers if equipped', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section7}
                onFieldChange={(key, value) => handleFieldChange('section7', key, value)}
            />

            {/* Section 8 */}
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'nearPowerLines', label: 'Proper positioning of equipment when within vicinity of power lines', type: 'checkboxLeft'},
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'fallTipHazards', label: 'Proper positioning of equipment when near fall/tip over hazards', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'controlNotInView', label: 'Proper position of control when not in view - regardless of distance', type: 'checkboxLeft' },
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'emergencyOperation', label: 'Emergency operation to lower equipment due to power loss', type: 'checkboxLeft' }
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />
            <TrainingFormSection
                title=""
                fields={[
                    { key: 'maxSideForce', label: 'Understanding of the maximum allowable side force (horizontal load)', type: 'checkboxLeft' }
                ]}
                values={formData.section8}
                onFieldChange={(key, value) => handleFieldChange('section8', key, value)}
            />

            {/* Section 9 */}
            <TrainingFormSection
                title="Demonstration of proper equipment operation:"
                fields={[
                    { key: 'properMounting', label: 'Proper mounting and dismounting', type: 'checkbox' },
                    { key: 'directionOfTravel', label: 'Looking in the direction of travel', type: 'checkbox' },
                    { key: 'weightCapacity', label: 'Weight and personnel capacity', type: 'checkbox' },
                    { key: 'appropriateSpeeds', label: 'Appropriate speeds', type: 'checkbox' },
                    { key: 'unlevelGround', label: 'Un-level Ground & Speed bumps', type: 'checkbox' },
                    { key: 'properSurface', label: 'Proper adjustment to wet or uneven driving surface', type: 'checkbox' },
                    { key: 'cornering', label: 'Cornering', type: 'checkbox' },
                    { key: 'soundingIntersections', label: 'Sounding horn at corners and intersections', type: 'checkbox' },
                ]}
                values={formData.section9}
                onFieldChange={(key, value) => handleFieldChange('section9', key, value)}
            />

            {/* Section 10 */}
            <TrainingFormSection
                title="Workplace conditions:"
                fields={[
                    { key: 'properParking', label: 'Demonstrates proper parking', type: 'checkbox' },
                    { key: 'hazardousAreas', label: 'Awareness of dangerous or hazardous areas', type: 'checkbox' },
                    { key: 'awareOfEmployees', label: 'Awareness of other employees', type: 'checkbox' },
                    { key: 'otherEnabled', label: 'Other', type: 'checkbox' },
                    { key: 'other', label: 'Other', type: 'text', conditionalOn: 'otherEnabled' },
                ]}
                values={formData.section10}
                onFieldChange={(key, value) => handleFieldChange('section10', key, value)}
            />

            {/* Signature */}
            <Box
                sx={{
                    mt: 3,
                    p: 2,
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9'
                }}
            >
                {/* Section 11 */}
                <TrainingFormSection
                    title=""
                    fields={[
                        { key: 'success', label: 'Successfully completed maneuvering practice drills.', type: 'checkboxLeft' }
                    ]}
                    values={formData.section11}
                    onFieldChange={(key, value) => handleFieldChange('section11', key, value)}
                />

                {formData.signature ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
                        <img
                            src={formData.signature}
                            alt="Signature"
                            style={{ maxWidth: '200px', border: '1px solid #aaa' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                            disabled={formData.status === STATUS_SEND || !formData.section11.success || isUploading}
                        >
                            Change Signature
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowSignatureModal(true)}
                            disabled={formData.status === STATUS_SEND || !formData.section11.success || isUploading}
                        >
                            Add Signature
                        </Button>
                    </Box>
                )}
            </Box>

            {/* Buttons */}
            <Box sx={{ textAlign: 'right', mt: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSend()}
                    disabled={isButtonDisabled}
                    sx={{
                        ...sendButtonStyle,
                        minWidth: '120px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {isUploading ? (
                        <>
                            <CircularProgress
                                size={24}
                                sx={{ color: '#fff', mr: 1 }}
                            />
                            Uploading...
                        </>
                    ) : (
                        'Send'
                    )}
                </Button>
            </Box>

            {showSignatureModal && (
                <SignatureModal
                    sigPadRef={sigPadRef}
                    existingSignature={formData.signature}
                    onClose={() => setShowSignatureModal(false)}
                    onSave={handleSignatureSaved}
                />
            )}
        </Box>
    );
}
