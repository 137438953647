import React from 'react';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    styled,
    StepIconProps
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const completedColor = '#4caf50';
const activeColor = '#1976d2';
const upcomingColor = '#bdbdbd';

function CustomStepIcon(props: StepIconProps) {
    const { active, completed, icon, className } = props;
    const stepNumber = icon;

    if (completed) {
        return <CheckCircleIcon className={className} sx={{ color: completedColor }} />;
    }
    if (active) {
        return (
            <Box
                className={className}
                sx={{
                    width: 28,
                    height: 28,
                    borderRadius: '50%',
                    backgroundColor: activeColor,
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 600
                }}
            >
                {stepNumber}
            </Box>
        );
    }
    return (
        <Box
            className={className}
            sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                border: `2px solid ${upcomingColor}`,
                color: upcomingColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.8rem'
            }}
        >
            {stepNumber}
        </Box>
    );
}

const CustomStepLabel = styled(StepLabel)(() => ({
    cursor: 'pointer',
    '& .MuiStepLabel-label': {
        fontSize: '0.9rem',
        transition: 'color 0.2s',
        '&.Mui-active': {
            color: activeColor,
            fontWeight: 700,
        },
        '&.Mui-completed': {
            color: completedColor,
            fontWeight: 700,
        },
        '&.Mui-disabled': {
            color: upcomingColor,
        }
    },
}));

export default function StepHeader({ steps, activeStep = 0, onChange }) {
    const handleStepClick = (index) => {
        if (onChange) {
            // allow clicking on completed steps or the active step
            if (index <= activeStep) {
                onChange(index);
            }
        }
    };

    return (
        <Box sx={{ mb: 2 }}>
            <Stepper alternativeLabel>
                {steps.map((label, index) => (
                    <Step
                        key={label}
                        active={index === activeStep}
                        completed={index < activeStep}
                    >
                        <CustomStepLabel
                            StepIconComponent={CustomStepIcon}
                            onClick={() => handleStepClick(index)}
                        >
                            {label}
                        </CustomStepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
