import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Divider
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeDB } from '../../../../config/db';
import {
    WELDING_FORM_STORE,
    EMPLOYEE_STORE,
    JOB_STORE,
    INI_STORE,
    STATUS_PROCESS,
    STATUS_SEND,
    STATUS_FINISH
} from '../../../../config/constants';
import { saveWeldingForm, markWeldingFormSend, markWeldingFormStatus } from '../../../../services/weldingFormService';
import SignatureModal from '../../SignatureModal';
import axiosInstance from "../../../../axiosInstance";

export default function WeldingQvalForm() {
    const { state } = useLocation() || {};
    const selectedTrainingDue = state?.selectedTrainingDue || null;
    const trainingDueIdFromRoute = selectedTrainingDue?.id || state?.trainingDueId || null;
    const navigate = useNavigate();
    const sigPadRefEmployee = useRef(null);

    // Удалены состояния и рефы для ручного ввода менеджерской подписи

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [showSignatureEmployee, setShowSignatureEmployee] = useState(false);

    // Обновленная функция генерации подписи:
    const generateSignature = (text) => {
        // Создаем временную канву для измерения текста
        const tempCanvas = document.createElement("canvas");
        const tempContext = tempCanvas.getContext("2d");
        const fontSize = 48;
        const fontFamily = "Pacifico";
        tempContext.font = `${fontSize}px ${fontFamily}`;

        // Измеряем ширину текста
        const textMetrics = tempContext.measureText(text);
        const textWidth = textMetrics.width;
        const padding = 20; // запас по краям

        // Устанавливаем ширину канвы динамически, чтобы весь текст поместился
        const canvasWidth = Math.ceil(textWidth + padding);
        const canvasHeight = fontSize + 20; // высота канвы с запасом

        const canvas = document.createElement("canvas");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const context = canvas.getContext("2d");
        context.font = `${fontSize}px ${fontFamily}`;
        context.textBaseline = "middle";
        context.fillStyle = "black";

        // Рисуем текст с небольшим отступом слева
        context.fillText(text, 10, canvasHeight / 2);
        return canvas.toDataURL("image/png");
    };

    const loadData = useCallback(async () => {
        const db = await initializeDB();

        // Загружаем данные сотрудников
        const txEmp = db.transaction(EMPLOYEE_STORE, 'readonly');
        const storeEmp = txEmp.objectStore(EMPLOYEE_STORE);
        const allEmployees = await storeEmp.getAll();
        await txEmp.done;
        const employeeId = localStorage.getItem('employeeId');
        let defaultWitness = null;
        if (employeeId) {
            defaultWitness = allEmployees.find(e => e.employeeId === employeeId);
        }

        // Загружаем список работ
        const txJobs = db.transaction(JOB_STORE, 'readonly');
        const storeJobs = txJobs.objectStore(JOB_STORE);
        const jobList = await storeJobs.getAll();
        await txJobs.done;

        // Загружаем ранее сохранённые формы
        const txWelding = db.transaction(WELDING_FORM_STORE, 'readonly');
        const storeWelding = txWelding.objectStore(WELDING_FORM_STORE);
        const allWelding = await storeWelding.getAll();
        await txWelding.done;

        // Значения по умолчанию для формы
        const defaultFormData = {
            trainingDueId: trainingDueIdFromRoute || null,
            employeeName: selectedTrainingDue?.fullName || '',
            welderId: '',
            process: selectedTrainingDue?.course || '',
            qvalDate: new Date().toISOString().split('T')[0],
            requalDate: '',
            witnessId: '',
            witnessName: '',
            managerName: '',
            jobId: '',
            jobName: '',
            employeeSignature: null,
            employeeSignedAt: null,
            managerSignature: null,
            managerSignedAt: null,
            status: STATUS_PROCESS
        };

        let foundForm = null;
        if (trainingDueIdFromRoute) {
            foundForm = allWelding.find(f => f.trainingDueId === trainingDueIdFromRoute);
            if (!foundForm) {
                foundForm = { ...defaultFormData };
                if (defaultWitness) {
                    foundForm.witnessId = defaultWitness.id || '';
                    foundForm.witnessName = defaultWitness.FullName || '';
                }
            }
        } else {
            if (allWelding.length > 0) {
                allWelding.sort((a, b) => (b.id || 0) - (a.id || 0));
                foundForm = allWelding[0];
            } else {
                foundForm = { ...defaultFormData };
                if (defaultWitness) {
                    foundForm.witnessId = defaultWitness.id || '';
                    foundForm.witnessName = defaultWitness.FullName || '';
                }
            }
        }
        if (!foundForm.requalDate) {
            const d = new Date(foundForm.qvalDate);
            d.setMonth(d.getMonth() + 6);
            foundForm.requalDate = d.toISOString().split('T')[0];
        }
        if (!foundForm.welderId && foundForm.employeeName) {
            const matchedEmp = allEmployees.find(e => e.FullName === foundForm.employeeName);
            if (matchedEmp && matchedEmp.welderId) {
                foundForm.welderId = matchedEmp.welderId;
            }
        }

        // Загружаем данные из INI_STORE для автоматической установки менеджера
        const txINI = db.transaction(INI_STORE, 'readonly');
        const storeINI = txINI.objectStore(INI_STORE);
        const iniRecords = await storeINI.getAll();
        await txINI.done;
        const managerRecord = iniRecords.find(rec => rec.fieldName === "Welding QC Manager");
        if (managerRecord) {
            foundForm.managerName = managerRecord.fieldValue;
            // Генерируем подпись автоматически на основе имени менеджера
            foundForm.managerSignature = generateSignature(managerRecord.fieldValue);
            foundForm.managerSignedAt = new Date().toISOString();
        }

        setFormData(foundForm);
        setEmployees(allEmployees);
        setJobs(jobList);
        setLoading(false);
    }, [trainingDueIdFromRoute, selectedTrainingDue]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const autoSaveForm = useCallback(async updated => {
        const newId = await saveWeldingForm({ ...updated });
        setFormData(prev => (prev ? { ...prev, id: newId } : prev));
    }, []);

    const handleUpdateFormData = useCallback(
        (key, value) => {
            if (!formData) return;
            let updated = { ...formData, [key]: value };
            if (key === 'qvalDate') {
                const sixMonthsLater = new Date(value);
                sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
                updated.requalDate = sixMonthsLater.toISOString().split('T')[0];
            }
            if (updated.status !== STATUS_SEND) {
                updated.status = STATUS_PROCESS;
            }
            setFormData(updated);
            autoSaveForm(updated);
        },
        [formData, autoSaveForm]
    );

    const handleSelectChange = useCallback(
        (field, e) => {
            const { value } = e.target;
            if (!formData) return;
            let updated = { ...formData };
            if (field === 'jobId') {
                updated.jobId = value;
                const job = jobs.find(j => j.id === value);
                updated.jobName = job ? `${job.Job || ''} - ${job.JobName || ''}` : '';
            } else if (field === 'witnessId') {
                updated.witnessId = value;
                const emp = employees.find(emp => emp.id === value);
                updated.witnessName = emp ? emp.FullName : '';
            }
            if (updated.status !== STATUS_SEND) {
                updated.status = STATUS_PROCESS;
            }
            setFormData(updated);
            autoSaveForm(updated);
        },
        [formData, employees, jobs, autoSaveForm]
    );

    // Обработчики для подписи сотрудника остаются без изменений
    const handleSignatureSaved = useCallback(
        type => {
            if (!formData) return;
            const sigPadRef = type === 'employee' ? sigPadRefEmployee : null;
            if (!sigPadRef || !sigPadRef.current) return;
            if (sigPadRef.current.isEmpty()) {
                let updated = { ...formData, employeeSignature: null, employeeSignedAt: null };
                if (updated.status !== STATUS_SEND) {
                    updated.status = STATUS_PROCESS;
                }
                setFormData(updated);
                autoSaveForm(updated);
                return;
            }
            const base64Signature = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
            const now = new Date().toISOString();
            let updated = { ...formData, employeeSignature: base64Signature, employeeSignedAt: now };
            if (updated.status !== STATUS_SEND) {
                updated.status = STATUS_PROCESS;
            }
            setFormData(updated);
            autoSaveForm(updated);
        },
        [formData, autoSaveForm]
    );

    const handleDeleteSignature = useCallback(
        type => {
            if (!formData) return;
            let updated = { ...formData };
            if (type === 'employee') {
                updated.employeeSignature = null;
                updated.employeeSignedAt = null;
            }
            if (updated.status !== STATUS_SEND) {
                updated.status = STATUS_PROCESS;
            }
            setFormData(updated);
            autoSaveForm(updated);
        },
        [formData, autoSaveForm]
    );

    const canSign = !!(
        formData &&
        formData.employeeName &&
        formData.welderId &&
        formData.witnessId &&
        formData.managerName &&
        formData.managerSignature &&
        formData.qvalDate &&
        formData.requalDate &&
        formData.jobId
    );

    const canSend = canSign && !!formData.employeeSignature;

    const handleSend = useCallback(
        async (overrides) => {
            // Если вызвали handleSend() без аргументов, берём из стейта
            const dataToSend = overrides || formData;
            if (!dataToSend) return;

            // Повторная проверка «на всякий случай»:
            if (!(
                dataToSend.employeeName &&
                dataToSend.welderId &&
                dataToSend.witnessId &&
                dataToSend.managerName &&
                dataToSend.managerSignature &&
                dataToSend.qvalDate &&
                dataToSend.requalDate &&
                dataToSend.jobId &&
                dataToSend.employeeSignature
            )) {
                alert('Please fill out all fields and provide both signatures before sending.');
                return;
            }

            try {
                const payload = {
                    ...dataToSend,
                    formType: 'welding',
                    course: formData.process,
                    sendBy: localStorage.getItem('userId'),
                };

                await axiosInstance.post('/training/form/save', payload);

                if (dataToSend.id) {
                    await markWeldingFormStatus(dataToSend.id, STATUS_SEND);
                }
                alert('Data sent successfully.');
                navigate('/training-due');
            } catch (err) {
                if (dataToSend.id) {
                    await markWeldingFormStatus(dataToSend.id, STATUS_FINISH);
                }
                alert('Data was not saved but save locally');
                navigate('/training-due');
            }
        },
        [formData, navigate]
    );

    if (loading || !formData) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Button variant="contained" color="success" onClick={() => navigate('/training-due')} sx={{ mb: 3 }}>
                Back
            </Button>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                Welding Qualification Form
            </Typography>
            <Paper sx={{ mb: 4, p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Basic Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, width: 200 }}>Employee Name</TableCell>
                                <TableCell>
                                    <Typography>{formData.employeeName || ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Welder ID</TableCell>
                                <TableCell>
                                    <Typography>{formData.welderId || '—'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Process</TableCell>
                                <TableCell>
                                    <Typography>{formData.process || ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Qualification Date</TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        value={formData.qvalDate || ''}
                                        onChange={e => handleUpdateFormData('qvalDate', e.target.value)}
                                        size="small"
                                        sx={{ width: '170px' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Re-qualification Date</TableCell>
                                <TableCell>
                                    <Typography>{formData.requalDate || '—'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Witness</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        value={employees.find(emp => emp.id === formData.witnessId) || null}
                                        onChange={(event, newValue) => {
                                            const syntheticEvent = { target: { value: newValue ? newValue.id : '' } };
                                            handleSelectChange('witnessId', syntheticEvent);
                                        }}
                                        options={employees}
                                        getOptionLabel={option => option.FullName || ''}
                                        renderInput={params => (
                                            <TextField {...params} label="Select witness" variant="outlined" size="small" />
                                        )}
                                        sx={{ minWidth: '170px' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Job # / Name</TableCell>
                                <TableCell>
                                    <Autocomplete
                                        value={jobs.find(job => job.id === formData.jobId) || null}
                                        onChange={(event, newValue) => {
                                            const syntheticEvent = { target: { value: newValue ? newValue.id : '' } };
                                            handleSelectChange('jobId', syntheticEvent);
                                        }}
                                        options={jobs}
                                        getOptionLabel={option => `${option.Job} - ${option.JobName}` || ''}
                                        renderInput={params => (
                                            <TextField {...params} label="Select Job" variant="outlined" size="small" />
                                        )}
                                        sx={{ minWidth: '200px' }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                                <TableCell>
                                    <Typography sx={{ textTransform: 'capitalize' }}>{formData.status}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Typography variant="h6" sx={{ mb: 1 }}>
                Employee Signature
            </Typography>
            <Paper sx={{ mb: 4, p: 2 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, width: '25%' }}>Full Name</TableCell>
                                <TableCell sx={{ fontWeight: 600, width: '25%' }}>Signature</TableCell>
                                <TableCell sx={{ fontWeight: 600, width: '25%' }}>Date Signed</TableCell>
                                <TableCell sx={{ fontWeight: 600, width: '25%' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{formData.employeeName || '—'}</TableCell>
                                <TableCell>
                                    {formData.employeeSignature ? (
                                        <img
                                            src={formData.employeeSignature}
                                            alt="Employee signature"
                                            style={{ maxWidth: '200px' }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">No Signature</Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {formData.employeeSignedAt
                                        ? new Date(formData.employeeSignedAt).toLocaleString()
                                        : <Typography variant="body2" color="textSecondary">Not Signed</Typography>}
                                </TableCell>
                                <TableCell>
                                    {/* кнопка подписи зависит от canSign *** */}
                                    <Button
                                        variant="outlined"
                                        disabled={!canSign}
                                        onClick={() => setShowSignatureEmployee(true)}
                                        sx={{ mr: 2 }}
                                    >
                                        {formData.employeeSignature ? 'Change' : 'Sign'}
                                    </Button>
                                    {formData.employeeSignature && (
                                        <Button variant="outlined" color="error" onClick={() => handleDeleteSignature('employee')}>
                                            Delete
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Typography variant="h6" sx={{ mb: 1 }}>
                Manager Signature
            </Typography>
            <Paper sx={{ mb: 4, p: 2 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, width: '33%' }}>Manager Name</TableCell>
                                <TableCell sx={{ fontWeight: 600, width: '33%' }}>Signature</TableCell>
                                <TableCell sx={{ fontWeight: 600, width: '33%' }}>Date Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{formData.managerName || '—'}</TableCell>
                                <TableCell>
                                    {formData.managerSignature ? (
                                        <img
                                            src={formData.managerSignature}
                                            alt="Manager signature"
                                            style={{ maxWidth: '200px' }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">No Signature</Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {formData.managerSignedAt
                                        ? new Date(formData.managerSignedAt).toLocaleString()
                                        : <Typography variant="body2" color="textSecondary">Not Signed</Typography>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {/*  кнопка "Send" теперь зависит от canSend *** */}
            <Box sx={{ textAlign: 'right' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!canSend}
                    onClick={handleSend}
                >
                    Send
                </Button>
            </Box>
            {showSignatureEmployee && (
                <SignatureModal
                    existingSignature={formData.employeeSignature}
                    onClose={() => setShowSignatureEmployee(false)}
                    employeeName={formData.employeeName || 'Employee'}
                    roleTitle="Employee"
                    customNote=""
                    onSave={base64 => {
                        if (!base64) {
                            handleDeleteSignature('employee');
                        } else {
                            const now = new Date().toISOString();

                            // Создаём обновлённую копию formData
                            const updated = {
                                ...formData,
                                employeeSignature: base64,
                                employeeSignedAt: now
                            };

                            // Сохраняем в стейт и в indexedDB
                            setFormData(updated);
                            autoSaveForm(updated);

                            // Проверяем, все ли поля заполнены, включая новую подпись
                            const allFieldsFilled = !!(
                                updated.employeeName &&
                                updated.welderId &&
                                updated.witnessId &&
                                updated.managerName &&
                                updated.managerSignature &&
                                updated.qvalDate &&
                                updated.requalDate &&
                                updated.jobId &&
                                updated.employeeSignature
                            );

                            // Если всё ок — отправляем
                            if (allFieldsFilled) {
                                handleSend(updated);
                            }
                        }
                        setShowSignatureEmployee(false);
                    }}

                    sigPadRef={sigPadRefEmployee}
                />
            )}
        </Box>
    );
}
