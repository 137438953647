import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    IconButton,
    TableHead
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeDB } from '../../../config/db';
import { JHA_STORE, STATUS_NEW, STATUS_PROCESS } from '../../../config/constants';
import SelectEmployeesAndTempsModal from '../SelectEmployeesAndTempsModal';
import StepHeader from '../StepHeader';

export default function JhaStepOne() {
    const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
    const navigate = useNavigate();
    const { state } = useLocation() || {};
    const { isNew = false, jha = null } = state || {};
    const creatingRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [jhaData, setJhaData] = useState({
        id: null,
        status: '',
        stepOne: { employeesSelected: [] },
        createdAt: null
    });

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        setLoading(true);
        const db = await initializeDB();
        const jhaTx = db.transaction(JHA_STORE, 'readwrite');
        const jhaStore = jhaTx.objectStore(JHA_STORE);
        const allJhaRecords = await jhaStore.getAll();

        if (jha?.id) {
            const existing = await jhaStore.get(jha.id);
            if (existing) {
                setJhaData(existing);
                setSelectedEmployees(existing.stepOne.employeesSelected || []);
            } else {
                setJhaData(jha);
                setSelectedEmployees(jha.stepOne.employeesSelected || []);
            }
        } else if (isNew && !creatingRef.current) {
            creatingRef.current = true;
            const existingNew = allJhaRecords.find(r => r.status === STATUS_NEW);
            if (existingNew) {
                setJhaData(existingNew);
                setSelectedEmployees(existingNew.stepOne.employeesSelected || []);
            } else {
                const newId = allJhaRecords.length
                    ? Math.max(...allJhaRecords.map(i => i.id)) + 1
                    : 1;
                const base = {
                    id: newId,
                    status: STATUS_NEW,
                    stepOne: { employeesSelected: [] },
                    createdAt: new Date().toISOString()
                };
                await jhaStore.put(base);
                setJhaData(base);
            }
        }
        setLoading(false);
    }

    async function saveData(updated) {
        const db = await initializeDB();
        const tx = db.transaction(JHA_STORE, 'readwrite');
        const store = tx.objectStore(JHA_STORE);
        await store.put(updated);
    }

    function handleSelect(newSelected) {
        const updated = { ...jhaData };
        updated.stepOne.employeesSelected = newSelected;
        if (newSelected.length && updated.status === STATUS_NEW) {
            updated.status = STATUS_PROCESS;
        }
        if (!newSelected.length && updated.status === STATUS_PROCESS) {
            updated.status = STATUS_NEW;
        }
        setJhaData(updated);
        setSelectedEmployees(newSelected);
        saveData(updated);
    }

    function removeSelectedEmployee(index) {
        const updated = { ...jhaData };
        const arr = [...(updated.stepOne.employeesSelected || [])];
        arr.splice(index, 1);
        updated.stepOne.employeesSelected = arr;
        if (!arr.length && updated.status === STATUS_PROCESS) {
            updated.status = STATUS_NEW;
        }
        setJhaData(updated);
        setSelectedEmployees(arr);
        saveData(updated);
    }

    function handleStepChange(idx) {
        if (idx === 1) {
            if (!selectedEmployees.length) return;
            navigate('/jha/step-2', { state: { jha: jhaData, isNew: false } });
        }
    }

    function handleNext() {
        if (!selectedEmployees.length) return;
        navigate('/jha/step-2', { state: { jha: jhaData, isNew: false } });
    }

    function handleBack() {
        navigate('/jha');
    }

    if (loading) return <Box p={2}>Loading...</Box>;

    return (
        <Box p={2} display="flex" flexDirection="column" gap={2}>
            <Box sx={{ mb: 2 }}>
                <Button variant="contained" onClick={handleBack}>
                    Back
                </Button>
            </Box>
            <StepHeader steps={steps} activeStep={0} onChange={handleStepChange} />
            <Box display="flex" justifyContent="center" mt={1}>
                <Typography variant="h5">Step 1 - Select Employees</Typography>
            </Box>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6">Selected Employees</Typography>
                        <Button variant="contained" onClick={() => setModalOpen(true)}>
                            Select Employees
                        </Button>
                    </Box>
                    {!selectedEmployees.length && (
                        <Typography color="text.secondary" sx={{ mt: 1 }}>
                            No employees selected
                        </Typography>
                    )}
                    {!!selectedEmployees.length && (
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>Division / Agency</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedEmployees.map((emp, idx) => (
                                        <TableRow key={`${emp.FullName}-${idx}`}>
                                            <TableCell>{emp.FullName}</TableCell>
                                            <TableCell>
                                                {emp._type === 'employee' ? emp.Division : emp.Agency}
                                            </TableCell>
                                            <TableCell>
                                                {emp._type === 'employee' ? 'employee' : 'temp'}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton size="small" onClick={() => removeSelectedEmployee(idx)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={handleNext} disabled={!selectedEmployees.length}>
                    Next
                </Button>
            </Box>
            <SelectEmployeesAndTempsModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                alreadySelected={selectedEmployees}
                onSelect={handleSelect}
            />
        </Box>
    );
}
