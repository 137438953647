// src/components/trainingDue/SignatureModal.jsx

import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';

export default function SignatureModal({
                                           existingSignature = '',
                                           employeeName = '',
                                           roleTitle = '',
                                           customNote = '',
                                           onSave,
                                           onClose,
                                           sigPadRef
                                       }) {
    const containerRef = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(600);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                setCanvasWidth(containerWidth);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (existingSignature && sigPadRef.current) {
            sigPadRef.current.fromDataURL(existingSignature, {
                ratio: 1,
                width: canvasWidth,
                height: 200
            });
        }
    }, [existingSignature, canvasWidth, sigPadRef]);

    const clearSignature = () => {
        if (sigPadRef.current) {
            sigPadRef.current.clear();
        }
    };

    const acceptSignature = () => {
        if (!sigPadRef.current) return;
        // Если подпись вообще не сделана
        if (sigPadRef.current.isEmpty()) {
            onSave(null);
            return;
        }
        const base64Data = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
        onSave(base64Data);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                maxWidth: 600,
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                p: 3,
                boxShadow: 24,
                borderRadius: '8px',
                zIndex: 9999
            }}
        >
            <Typography variant="h6" gutterBottom>
                {roleTitle ? `Sign as ${roleTitle}` : 'Sign Here'}
            </Typography>
            {employeeName && (
                <Typography variant="subtitle2" gutterBottom>
                    {employeeName}
                </Typography>
            )}
            {customNote && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                    {customNote}
                </Typography>
            )}

            <Paper
                ref={containerRef}
                variant="outlined"
                sx={{ p: 0, mb: 2 }}
            >
                <SignaturePad
                    ref={sigPadRef}
                    canvasProps={{
                        width: canvasWidth,
                        height: 200,
                        style: { display: 'block' }
                    }}
                />
            </Paper>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button variant="outlined" onClick={clearSignature} fullWidth>
                        Clear
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={acceptSignature} fullWidth>
                        Accept
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" color="inherit" onClick={onClose} fullWidth>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
