// components/jha/JhaStepTwo/index.jsx
import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    IconButton,
    Divider
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeDB } from '../../../config/db';
import { JHA_STORE, STATUS_NEW, STATUS_PROCESS } from '../../../config/constants';
import StepHeader from '../StepHeader';
import GeneralInformation from './GeneralInformation';
import DocsPermitsSection from './DocsPermitsSection';
import WorkActivitiesSection from './WorkActivitiesSection';
import EvaluationSection from './EvaluationSection';
import EnergySection from './EnergySection';
import SelectEmployeeModal from '../SelectEmployeeModal';
import { getFullNameEmployeeBy } from '../../../services/employeeService';
import SignatureModal from "../SignatureModal";

export default function JhaStepTwo() {
    const { state } = useLocation() || {};
    const { isNew = false, jha = null } = state || {};
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [signatureModalOpen, setSignatureModalOpen] = useState(false);
    const [jhaData, setJhaData] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [jobTasks, setJobTasks] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [docsPermitsList, setDocsPermitsList] = useState([]);
    const [workActivitiesList, setWorkActivitiesList] = useState([]);
    const [evaluationList, setEvaluationList] = useState([]);
    const [energyList, setEnergyList] = useState([]);
    const [employeeModalOpen, setEmployeeModalOpen] = useState(false);
    const [supervisorModalOpen, setSupervisorModalOpen] = useState(false);
    const [otherTask, setOtherTask] = useState('');
    const [otherDoc, setOtherDoc] = useState('');
    const [otherWorkActivity, setOtherWorkActivity] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        setLoading(true);
        const db = await initializeDB();
        const [
            allEmployees,
            allJobs,
            allTasks,
            allConditions,
            allDocs,
            allWorkActs,
            allEval,
            allEnergy
        ] = await Promise.all([
            db.transaction('employees','readonly').objectStore('employees').getAll(),
            db.transaction('job','readonly').objectStore('job').getAll(),
            db.transaction('jobTask','readonly').objectStore('jobTask').getAll(),
            db.transaction('conditions','readonly').objectStore('conditions').getAll(),
            db.transaction('docsPermit','readonly').objectStore('docsPermit').getAll(),
            db.transaction('workActivities','readonly').objectStore('workActivities').getAll(),
            db.transaction('evaluationOfWork','readonly').objectStore('evaluationOfWork').getAll(),
            db.transaction('energy','readonly').objectStore('energy').getAll()
        ]);
        setEmployees(allEmployees);
        setJobs(allJobs);
        setJobTasks(allTasks);
        setConditions(allConditions);
        setDocsPermitsList(allDocs);
        setWorkActivitiesList(allWorkActs);
        setEvaluationList(allEval);
        setEnergyList(allEnergy);

        const personName = await getFullNameEmployeeBy(localStorage.getItem('employeeId'));
        const tx = db.transaction(JHA_STORE, 'readwrite');
        const store = tx.objectStore(JHA_STORE);
        let current = null;

        if (jha?.id) {
            current = await store.get(jha.id);
            if (current) {
                if (!current.stepTwo) {
                    current.stepTwo = createBlankStepTwo(allDocs, allEval, personName);
                }
                await store.put(current);
                setJhaData(current);
                setLoading(false);
                return;
            }
            if (!jha.stepTwo) {
                jha.stepTwo = createBlankStepTwo(allDocs, allEval, personName);
            }
            await store.put(jha);
            setJhaData(jha);
            setLoading(false);
            return;
        }

        if (isNew) {
            const allJha = await store.getAll();
            const newId = allJha.length ? Math.max(...allJha.map(i => i.id)) + 1 : 1;
            const base = {
                id: newId,
                status: STATUS_NEW,
                stepOne: { employeesSelected: [] },
                stepTwo: createBlankStepTwo(allDocs, allEval, personName),
                createdAt: new Date().toISOString()
            };
            await store.put(base);
            setJhaData(base);
        }
        setLoading(false);
    }

    function createBlankStepTwo(docs, evals, user) {
        return {
            personCompleting: user || '',
            jobNumber: '',
            date: new Date().toISOString().slice(0,10),
            jobSiteName: '',
            jobSiteAddress: '',
            tasks: [],
            customTasks: [],
            jobSiteSupervisor: null,
            weatherConditions: [],
            docsPermits: docs.map(d => ({ value: d.value, required: null })),
            customDocsPermits: [],
            workActivities: [],
            customWorkActivities: [],
            evaluationOfWork: evals.map(e => ({ value: e.value, selected: '' })),
            energyExposures: [],
            supervisorSignature: '',
            // Новое поле для textarea
            sequenceOfJobSteps: ''
        };
    }

    async function saveData(obj) {
        if (!obj?.id) return;
        const db = await initializeDB();
        const t = db.transaction(JHA_STORE, 'readwrite');
        const s = t.objectStore(JHA_STORE);
        await s.put({ ...obj });
    }

    async function saveField(name, val) {
        if (!jhaData) return;
        const c = { ...jhaData };
        if (!c.stepTwo) c.stepTwo = {};
        c.stepTwo[name] = val;

        // Логика смены статуса, если номер job заполняется или очищается
        if (!c.stepTwo.jobNumber && c.status === STATUS_PROCESS) {
            c.status = STATUS_NEW;
        } else if (c.stepTwo.jobNumber && c.status === STATUS_NEW) {
            c.status = STATUS_PROCESS;
        }

        setJhaData(c);
        await saveData(c);
    }

    async function saveMultipleFields(fields) {
        if (!jhaData) return;
        const c = { ...jhaData };
        if (!c.stepTwo) c.stepTwo = {};

        for (const [k, v] of Object.entries(fields)) {
            c.stepTwo[k] = v;
        }

        // Логика смены статуса, если номер job заполняется или очищается
        if (!c.stepTwo.jobNumber && c.status === STATUS_PROCESS) {
            c.status = STATUS_NEW;
        } else if (c.stepTwo.jobNumber && c.status === STATUS_NEW) {
            c.status = STATUS_PROCESS;
        }

        setJhaData(c);
        await saveData(c);
    }

    function handleStepChange(i) {
        if (!jhaData) return;
        saveData(jhaData);
        if (i===0) navigate('/jha/step-1', { state: { jha: jhaData, isNew: false } });
        if (i===2 && isFormValid) navigate('/jha/step-3', { state: { jha: jhaData, isNew: false } });
        if (i===3 && isFormValid) navigate('/jha/step-4', { state: { jha: jhaData, isNew: false } });
    }

    function handlePrev() {
        if (!jhaData) return;
        saveData(jhaData);
        navigate('/jha/step-1', { state: { jha: jhaData, isNew: false } });
    }

    function handleNext() {
        if (!isFormValid || !jhaData) return;
        saveData(jhaData);
        navigate('/jha/step-3', { state: { jha: jhaData, isNew: false } });
    }

    const isAllButSignatureValid = useMemo(() => {
        if (!jhaData?.id) return false;
        const s = jhaData.stepTwo || {};
        if (!s.personCompleting) return false;
        if (!s.jobNumber) return false;
        if (!s.tasks.length && !s.customTasks.length) return false;
        if (!s.workActivities.length && !s.customWorkActivities.length) return false;
        if (!s.energyExposures.length) return false;
        if (!s.docsPermits.every(d => d.required !== null)) return false;
        if (!s.customDocsPermits.every(d => d.required !== null)) return false;
        if (!s.evaluationOfWork.every(e => e.selected)) return false;
        return true;
    }, [jhaData]);

    const isFormValid = useMemo(() => {
        if (!isAllButSignatureValid) return false;
        const s = jhaData?.stepTwo || {};
        if (!s.supervisorSignature) return false;
        return true;
    }, [isAllButSignatureValid, jhaData]);

    if (loading) return <Box p={2}>Loading...</Box>;
    if (!jhaData) return <Box p={2}>No JHA Found</Box>;

    return (
        <Box p={2}>
            <StepHeader
                steps={['Step 1','Step 2','Step 3','Step 4']}
                activeStep={1}
                onChange={handleStepChange}
            />
            <Typography variant="h5" align="center">Step 2 - Job Hazard Analysis</Typography>

            <GeneralInformation
                jhaData={jhaData}
                jobs={jobs}
                conditions={conditions}
                onSaveField={saveField}
                onSaveMultipleFields={saveMultipleFields}
                openEmployeeModal={() => setEmployeeModalOpen(true)}
                openSupervisorModal={() => setSupervisorModalOpen(true)}
            />

            {/* Ниже — существующий блок "Detailed Summary of Job/Task" */}
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6">Detailed Summary of Job/Task</Typography>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        {(jhaData.stepTwo?.tasks || []).map && jobTasks.map(t => (
                            <Grid item xs={12} sm={6} md={4} key={t.value}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={jhaData.stepTwo.tasks.includes(t.value)}
                                            onChange={() => {
                                                const arr = [...jhaData.stepTwo.tasks];
                                                if (arr.includes(t.value)) {
                                                    arr.splice(arr.indexOf(t.value), 1);
                                                } else {
                                                    arr.push(t.value);
                                                }
                                                saveField('tasks', arr);
                                            }}
                                        />
                                    }
                                    label={t.value}
                                />
                            </Grid>
                        ))}
                        {(jhaData.stepTwo?.customTasks || []).map(ct => (
                            <Grid item xs={12} sm={6} md={4} key={ct}>
                                <Box display="flex" alignItems="center">
                                    <FormControlLabel
                                        control={<Checkbox checked />}
                                        label={ct}
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            const arr = jhaData.stepTwo.customTasks.filter(x => x !== ct);
                                            saveField('customTasks', arr);
                                        }}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box display="flex" gap={1} mt={2}>
                        <TextField
                            size="small"
                            label="Other"
                            value={otherTask}
                            onChange={e => setOtherTask(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!otherTask.trim()) return;
                                const arr = [...(jhaData.stepTwo?.customTasks || []), otherTask.trim()];
                                setOtherTask('');
                                saveField('customTasks', arr);
                            }}
                        >
                            Add
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            <DocsPermitsSection
                jhaData={jhaData}
                onSaveDocsPermits={async (val, req) => {
                    const c = { ...jhaData };
                    c.stepTwo.docsPermits = c.stepTwo.docsPermits.map(x =>
                        x.value === val ? { ...x, required: req } : x
                    );
                    setJhaData(c);
                    await saveData(c);
                }}
                onSaveCustomDocsPermits={async (val, req) => {
                    const c = { ...jhaData };
                    c.stepTwo.customDocsPermits = c.stepTwo.customDocsPermits.map(x =>
                        x.value === val ? { ...x, required: req } : x
                    );
                    setJhaData(c);
                    await saveData(c);
                }}
                onRemoveCustomDoc={val => {
                    const c = { ...jhaData };
                    c.stepTwo.customDocsPermits = c.stepTwo.customDocsPermits.filter(x => x.value !== val);
                    setJhaData(c);
                    saveData(c);
                }}
                otherDoc={otherDoc}
                setOtherDoc={setOtherDoc}
                addCustomDoc={() => {
                    if (!otherDoc.trim()) return;
                    const c = { ...jhaData };
                    c.stepTwo.customDocsPermits.push({ value: otherDoc.trim(), required: null });
                    setOtherDoc('');
                    setJhaData(c);
                    saveData(c);
                }}
            />

            <WorkActivitiesSection
                jhaData={jhaData}
                workActivitiesList={workActivitiesList}
                onToggleWorkActivity={val => {
                    const c = { ...jhaData };
                    const arr = [...c.stepTwo.workActivities];
                    if (arr.includes(val)) {
                        arr.splice(arr.indexOf(val), 1);
                    } else {
                        arr.push(val);
                    }
                    c.stepTwo.workActivities = arr;
                    setJhaData(c);
                    saveData(c);
                }}
                onRemoveCustomWorkActivity={val => {
                    const c = { ...jhaData };
                    c.stepTwo.customWorkActivities = c.stepTwo.customWorkActivities.filter(x => x !== val);
                    setJhaData(c);
                    saveData(c);
                }}
                otherWorkActivity={otherWorkActivity}
                setOtherWorkActivity={setOtherWorkActivity}
                addCustomWorkActivity={() => {
                    if (!otherWorkActivity.trim()) return;
                    const c = { ...jhaData };
                    c.stepTwo.customWorkActivities.push(otherWorkActivity.trim());
                    setOtherWorkActivity('');
                    setJhaData(c);
                    saveData(c);
                }}
            />

            <EvaluationSection
                jhaData={jhaData}
                onSaveEvaluation={async (val, sel) => {
                    const c = { ...jhaData };
                    c.stepTwo.evaluationOfWork = c.stepTwo.evaluationOfWork.map(e =>
                        e.value === val ? { ...e, selected: sel } : e
                    );
                    setJhaData(c);
                    await saveData(c);
                }}
            />

            <EnergySection
                jhaData={jhaData}
                energyList={energyList}
                onSave={async (updated) => {
                    setJhaData(updated);
                    await saveData(updated);
                }}
            />

            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Please list the sequence of job steps/tasks addressing energies identified:
                    </Typography>
                    <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        value={jhaData.stepTwo?.sequenceOfJobSteps || ''}
                        onChange={(e) => saveField('sequenceOfJobSteps', e.target.value)}
                    />
                </CardContent>
            </Card>


            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6">Supervisor Pre-Shift Signature</Typography>
                    <Box mt={1} mb={2}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            ({ jhaData.stepTwo?.personCompleting?.FullName || jhaData.stepTwo?.personCompleting })
                        </Typography>
                    </Box>
                    {jhaData.stepTwo?.supervisorSignature ? (
                        <Box
                            component="img"
                            sx={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                maxWidth: '100%',
                                height: 'auto'
                            }}
                            alt="Supervisor Signature"
                            src={jhaData.stepTwo.supervisorSignature}
                        />
                    ) : (
                        <Typography variant="body2" sx={{ color: 'GrayText' }}>
                            No signature yet
                        </Typography>
                    )}
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            disabled={!isAllButSignatureValid}
                            onClick={() => setSignatureModalOpen(true)}
                        >
                            Add/Change Signature
                        </Button>
                    </Box>
                </CardContent>
            </Card>



            <Divider sx={{ my:2 }} />

            <Box display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={handlePrev}>
                    Prev
                </Button>
                <Button variant="contained" disabled={!isFormValid} onClick={handleNext}>
                    Next
                </Button>
            </Box>

            <SelectEmployeeModal
                open={employeeModalOpen}
                onClose={() => setEmployeeModalOpen(false)}
                employees={employees}
                onSelect={async emp => {
                    const c = { ...jhaData };
                    c.stepTwo.personCompleting = emp;
                    setJhaData(c);
                    await saveData(c);
                }}
            />

            <SelectEmployeeModal
                open={supervisorModalOpen}
                onClose={() => setSupervisorModalOpen(false)}
                employees={employees}
                onSelect={async emp => {
                    const c = { ...jhaData };
                    c.stepTwo.jobSiteSupervisor = emp;
                    setJhaData(c);
                    await saveData(c);
                }}
            />

            <SignatureModal
                open={signatureModalOpen}
                onClose={() => setSignatureModalOpen(false)}
                employeeName={ jhaData.stepTwo?.personCompleting?.FullName || jhaData.stepTwo?.personCompleting }
                existingSignature={ jhaData.stepTwo?.supervisorSignature }
                onSave={sig => {
                    const copy = { ...jhaData };
                    copy.stepTwo.supervisorSignature = sig;
                    setJhaData(copy);
                    saveData(copy);
                    setSignatureModalOpen(false);
                }}
            />
        </Box>
    );
}
