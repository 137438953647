import { initializeDB } from '../config/db';
import { EMPLOYEE_STORE} from '../config/constants';

export async function getEmployeesFromDB() {
    try {
        const db = await initializeDB();
        const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
        const store = tx.objectStore(EMPLOYEE_STORE);

        // getAll() вернёт массив всех записей из object store 'employees'
        const employees = await store.getAll();
        await tx.done; // Закрываем транзакцию
        return employees;

    } catch (error) {
        console.error('Error retrieving employees from IndexedDB:', error);
        return [];
    }
}

export async function getEmployeeById(id) {
    try {
        const db = await initializeDB();
        const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
        const store = tx.objectStore(EMPLOYEE_STORE);

        const employee = await store.get(id); // Ищет запись с ключом "id"
        await tx.done;
        return employee || null;
    } catch (error) {
        console.error(`Error retrieving employee with id=${id}:`, error);
        return null;
    }
}

export async function getFullNameEmployeeBy(id) {
    try {
        const db = await initializeDB();
        const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
        const store = tx.objectStore(EMPLOYEE_STORE);
        const all = await store.getAll();
        await tx.done;
        return all.find(e => parseInt(e.employeeId) === parseInt(id)) || null;

    } catch (error) {
        console.error(`Error retrieving employee with id=${id}:`, error);
        return null;
    }
}