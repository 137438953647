import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    CircularProgress,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from "../../axiosInstance";
import { initializeDB } from '../../config/db';
import {
    SSD_STORE,
    EMPLOYEE_STORE,
    STATUS_PROCESS,
    STATUS_FINISH,
    STATUS_SEND
} from '../../config/constants';
import { getTodayDate } from '../../utils/dateUtils';
import SSDSignModal from './SSDSignModal';
import SSDGivenBySingleSelectModal from './SSDGivenByModal';
import SelectEmployeesAndTempsModal from './SelectEmployeesAndTempsModal';

export default function SSDDetail() {
    const navigate = useNavigate();
    const [ssdId, setSsdId] = useState(null);
    const [topic, setTopic] = useState('');
    const [participants, setParticipants] = useState([]);
    const [givenBy, setGivenBy] = useState(null);
    const [date, setDate] = useState(getTodayDate());
    const [status, setStatus] = useState(STATUS_PROCESS);
    const sendBy = localStorage.getItem('employeeId');
    const [allEmployees, setAllEmployees] = useState([]);
    const [givenByEmployee, setGivenByEmployee] = useState(null);
    const [openSelectModal, setOpenSelectModal] = useState(false);
    const [openGivenByModal, setOpenGivenByModal] = useState(false);
    const [openSignModal, setOpenSignModal] = useState(false);
    const sigPadRef = useRef(null);
    const [selectedParticipantIndex, setSelectedParticipantIndex] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const didInitRef = useRef(false);

    useEffect(() => {
        if (didInitRef.current) return;
        didInitRef.current = true;
        (async () => {
            try {
                const db = await initializeDB();
                const empTx = db.transaction(EMPLOYEE_STORE, 'readonly');
                const empStore = empTx.objectStore(EMPLOYEE_STORE);
                const employeesAll = await empStore.getAll();
                await empTx.done;
                setAllEmployees(employeesAll || []);
                const readTx = db.transaction(SSD_STORE, 'readonly');
                const ssdStore = readTx.objectStore(SSD_STORE);
                const allRecords = await ssdStore.getAll();
                await readTx.done;
                let record = allRecords.find(r => r.status === STATUS_PROCESS);
                if (!record) {
                    const newRecord = {
                        topic: '',
                        employees: [],
                        givenBy: null,
                        date: getTodayDate(),
                        status: STATUS_PROCESS
                    };
                    const writeTx = db.transaction(SSD_STORE, 'readwrite');
                    const wStore = writeTx.objectStore(SSD_STORE);
                    const newKey = await wStore.add(newRecord);
                    await writeTx.done;
                    newRecord.id = newKey;
                    record = newRecord;
                }
                if (!record.givenBy) {
                    const empIdFromStorage = localStorage.getItem('employeeId');
                    if (empIdFromStorage) {
                        const foundEmp = employeesAll.find(
                            e => String(e.employeeId) === String(empIdFromStorage)
                        );
                        if (foundEmp) {
                            record.givenBy = { employeeId: foundEmp.employeeId };
                        }
                    }
                    const writeTx2 = db.transaction(SSD_STORE, 'readwrite');
                    const wStore2 = writeTx2.objectStore(SSD_STORE);
                    await wStore2.put(record);
                    await writeTx2.done;
                }
                setSsdId(record.id);
                setTopic(record.topic || '');
                setParticipants(record.employees || []);
                setGivenBy(record.givenBy || null);
                setDate(record.date || getTodayDate());
                setStatus(record.status || STATUS_PROCESS);
            } catch (error) {
                console.error('Error init SSD record:', error);
            }
        })();
    }, []);

    useEffect(() => {
        if (givenBy?.employeeId) {
            const found = allEmployees.find(e => e.employeeId === givenBy.employeeId);
            setGivenByEmployee(found || null);
        } else {
            setGivenByEmployee(null);
        }
    }, [givenBy, allEmployees]);

    async function saveRecordAndReload({
                                           newStatus = STATUS_PROCESS,
                                           newDate,
                                           newParticipants = null,
                                           newGivenBy = null
                                       } = {}) {
        if (!ssdId) return;
        const finalParticipants = newParticipants ?? participants;
        const finalGivenBy = newGivenBy ?? givenBy;
        const recordToSave = {
            id: ssdId,
            topic,
            employees: finalParticipants,
            givenBy: finalGivenBy,
            date: newDate ?? date,
            status: newStatus
        };
        try {
            const db = await initializeDB();
            const writeTx = db.transaction(SSD_STORE, 'readwrite');
            const store = writeTx.objectStore(SSD_STORE);
            await store.put(recordToSave);
            await writeTx.done;
            setTopic(recordToSave.topic);
            setParticipants(recordToSave.employees);
            setGivenBy(recordToSave.givenBy);
            setDate(recordToSave.date);
            setStatus(recordToSave.status);
        } catch (err) {
            console.error('Error saving SSD record:', err);
        }
    }

    const handleTopicChange = e => setTopic(e.target.value);
    const handleTopicBlur = () => {
        saveRecordAndReload({ newStatus: STATUS_PROCESS });
    };
    const handleDateChange = e => {
        const newDate = e.target.value;
        setDate(newDate);
        saveRecordAndReload({ newStatus: STATUS_PROCESS, newDate });
    };

    const handleOpenSelectModal = () => setOpenSelectModal(true);

    const handleSaveParticipants = async selectedArr => {
        const newList = selectedArr.map(sel => {
            const existing = participants.find(
                p => p.FullName === sel.FullName && p._type === sel._type
            );
            return {
                FullName: sel.FullName,
                _type: sel._type,
                Division: sel.Division || '',
                Agency: sel.Agency || '',
                signature: existing?.signature || null,
                signedAt: existing?.signedAt || null
            };
        });
        setParticipants(newList);
        setOpenSelectModal(false);
        await saveRecordAndReload({ newStatus: STATUS_PROCESS, newParticipants: newList });
    };

    const handleDeleteParticipant = async idx => {
        const newArr = [...participants];
        newArr.splice(idx, 1);
        setParticipants(newArr);
        await saveRecordAndReload({ newStatus: STATUS_PROCESS, newParticipants: newArr });
    };

    const handleOpenSignModal = idx => {
        setSelectedParticipantIndex(idx);
        setOpenSignModal(true);
    };

    const handleAcceptSignature = async () => {
        if (!sigPadRef.current) return;
        const signatureURL = sigPadRef.current.toDataURL();
        const now = new Date().toISOString();
        setOpenSignModal(false);
        const updated = [...participants];
        updated[selectedParticipantIndex] = {
            ...updated[selectedParticipantIndex],
            signature: signatureURL,
            signedAt: now
        };
        setParticipants(updated);
        await saveRecordAndReload({ newStatus: STATUS_PROCESS, newParticipants: updated });
    };

    const handleOpenGivenByModal = () => {
        setOpenGivenByModal(true);
    };

    const handleSaveGivenBy = async emp => {
        if (!emp) {
            setOpenGivenByModal(false);
            return;
        }
        const newVal = { employeeId: emp.employeeId };
        setGivenBy(newVal);
        setOpenGivenByModal(false);
        await saveRecordAndReload({ newStatus: STATUS_PROCESS, newGivenBy: newVal });
    };

    const handleSend = async () => {
        if (!isFormValid()) {
            await saveRecordAndReload({ newStatus: STATUS_FINISH });
            alert('Please fill all fields.');
            return;
        }
        setIsSending(true);
        try {
            const payload = {
                topic,
                employees: participants,
                givenBy,
                date,
                sendBy
            };

            const resp = await axiosInstance.post('/safety-stand-down/save', payload);

            if (resp.status === 200) {
                await saveRecordAndReload({ newStatus: STATUS_SEND });
                alert('SSD form sent successfully!');
                navigate('/');
            } else {
                alert('Server code: ' + resp.status);
            }
        } catch (err) {
            console.error('Error sending form:', err);
            alert('Error sending form.');
        } finally {
            setIsSending(false);
        }
    };

    function isFormValid() {
        if (!topic.trim()) return false;
        if (!participants || participants.length === 0) return false;
        if (!participants.every(e => e.signature)) return false;
        if (!givenBy || !givenBy.employeeId) return false;
        if (!date) return false;
        return true;
    }

    const currentSigningName = (() => {
        if (selectedParticipantIndex === null) return 'Unknown';
        const p = participants[selectedParticipantIndex];
        return p?.FullName || 'Unknown';
    })();

    return (
        <Box sx={{ p: 2 }}>
            <Link to="/">
                <Button variant="contained" color="success" sx={{ mb: 2 }}>
                    Back
                </Button>
            </Link>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h5">Topic:</Typography>
                <TextField
                    fullWidth
                    placeholder="Enter SSD topic"
                    value={topic}
                    onChange={handleTopicChange}
                    onBlur={handleTopicBlur}
                />
            </Box>
            <Typography variant="h5" sx={{ mt: 3 }}>
                Participants:
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleOpenSelectModal}
            >
                EDIT PARTICIPANTS
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Signature</TableCell>
                            <TableCell>Signed At</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {participants.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No participants
                                </TableCell>
                            </TableRow>
                        ) : (
                            participants.map((p, idx) => (
                                <TableRow key={`${p._type}-${p.FullName}-${idx}`}>
                                    <TableCell>{p.FullName}</TableCell>
                                    <TableCell>
                                        {p.signature ? (
                                            <img
                                                src={p.signature}
                                                alt="Signature"
                                                style={{ width: '120px', height: '40px', border: '1px solid #ccc' }}
                                            />
                                        ) : (
                                            'No Signature'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {p.signedAt ? new Date(p.signedAt).toLocaleString() : '---'}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            sx={{ mr: 1 }}
                                            onClick={() => handleOpenSignModal(idx)}
                                        >
                                            {p.signature ? 'Re-Sign' : 'Sign'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleDeleteParticipant(idx)}
                                        >
                                            REMOVE
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper sx={{ p: 2, mt: 4 }}>
                <Typography variant="h6">Given By & Date</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 3,
                        mt: 2
                    }}
                >
                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Given By:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body1" sx={{ minWidth: '220px' }}>
                                {givenByEmployee ? givenByEmployee.FullName : 'No "Given By" selected'}
                            </Typography>
                            <IconButton onClick={handleOpenGivenByModal}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Date:
                        </Typography>
                        <TextField
                            type="date"
                            value={date}
                            onChange={handleDateChange}
                            sx={{ width: '220px' }}
                        />
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ mt: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSend}
                    disabled={!isFormValid() || isSending}
                >
                    {isSending ? <CircularProgress size={18} sx={{ mr: 1 }} /> : null}
                    {isSending ? 'Sending...' : 'Send'}
                </Button>
            </Box>
            <SelectEmployeesAndTempsModal
                open={openSelectModal}
                onClose={() => setOpenSelectModal(false)}
                onSelect={handleSaveParticipants}
                alreadySelected={participants}
            />
            <SSDGivenBySingleSelectModal
                open={openGivenByModal}
                onClose={() => setOpenGivenByModal(false)}
                onSave={handleSaveGivenBy}
            />
            <SSDSignModal
                open={openSignModal}
                onClose={() => setOpenSignModal(false)}
                sigPadRef={sigPadRef}
                employeeName={currentSigningName}
                onAccept={handleAcceptSignature}
            />
        </Box>
    );
}
