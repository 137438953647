import DocumentIcon from "@mui/icons-material/Description";
import CreditCard from "@mui/icons-material/CreditCard";
import NotesIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import TrainingIcon from "@mui/icons-material/School";
import GppGoodIcon from '@mui/icons-material/GppGood';
import React from "react";

export const option = [
    {
        name: 'TBT',
        icon: <DocumentIcon fontSize="large" />,
        link: '/#/tbt',
    },
    {
        name: 'CC',
        icon: <CreditCard fontSize="large" />,
        link: '/#/cc',
    },
    {
        name: 'PO',
        icon: <NotesIcon fontSize="large" />,
        link: '/#/po',
    },
    {
        name: 'JSA',
        icon: <WorkIcon fontSize="large" />,
        link: '/#/jsa',
    },
    {
        name: 'Training',
        icon: <TrainingIcon fontSize="large" />,
        link: '/#/training-due',
        roles: ['ROLE_TRAINER'] // Только пользователи с ROLE_TRAINER
    },
    {
        name: 'Safety Stand Down',
        icon: <GppGoodIcon fontSize="large" />,
        link: '/#/ssd',
    },
    {
        name: 'JHA',
        icon: <WorkIcon fontSize="large" />,
        link: '/#/jha',
    },
];