import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from '../components/header/Header';
import JobSafetyAnalysisForm from '../components/forms/JobSafetyAnalysisForm';
import SavedForms from '../components/forms/SavedForms';
import SubmittedForms from '../components/forms/SubmittedForms';
import EditForm from '../components/forms/EditForm';
import MainPage from '../components/main/MainPage';
import TBTPage from '../components/tbt/TBTPage';
import TBTDetail from '../components/tbt/TBTDetail';
import TBTAdd from '../components/tbt/TBTAdd';
import Sync from '../components/sync/Sync';
import WeeklyEmployeeComponent from '../components/admin/WeeklyEmployeeComponent';
import VersionStatus from "../components/VersionDisplay";
import {TrainingPage} from "../components/training/TrainingPage";
import MepForm from "../components/training/forms/mep/MepForm";
import FtForm from "../components/training/forms/ft/FtForm";
import JobGlCaptureForm from "../components/creditCart/JobGlCaptureForm"
import TrainingDue from "../components/training/trainingDue/TrainingDue";
import TrainingDueDefaultForm from "../components/training/forms/default/TrainingDueDefaultForm";
import WeldingQvalForm from "../components/training/forms/welding/WeldingQvalForm";
import SSDDetail from "../components/ssd/SSDDetail";
import JhaStepOne from "../components/jha/step1/JhaStepOne";
import JHAPage from "../components/jha/JHAPage";
import JhaStepTwo from "../components/jha/step2/index";
import JhaStepThree from "../components/jha/step3/index";

function AuthenticatedLayout({ handleLogout, onShowUpdateModal, waitingWorker }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // экранная высота
            }}
        >
            <Header handleLogout={handleLogout} />
            {/* Контентный контейнер занимает всё доступное пространство */}
            <Box sx={{ flex: 1, paddingTop: '80px' /* отступ под хедер */ }}>
                <Container>
                    <Routes>
                        <Route path="/jsa" element={<JobSafetyAnalysisForm />} />
                        <Route path="/jsa/saved" element={<SavedForms />} />
                        <Route path="/jsa/submitted" element={<SubmittedForms />} />
                        <Route path="/jsa/edit/:id" element={<EditForm />} />

                        <Route path="/tbt" element={<TBTPage />} />
                        <Route path="/tbt/add" element={<TBTAdd />} />
                        <Route path="/tbt/:id" element={<TBTDetail />} />

                        <Route path="/cc" element={<JobGlCaptureForm />} />

                        <Route path="/po" element={<div>PO Page</div>} />
                        <Route path="/main" element={<MainPage />} />
                        <Route path="/" element={<MainPage />} />

                        <Route path="/admin/synchronization" element={<Sync />} />
                        <Route path="/admin/weekly-employee" element={<WeeklyEmployeeComponent />} />


                        <Route path="/training" element={<TrainingPage />} />

                        {/* Training Due pages */}
                        <Route path="/training-due" element={<TrainingDue />} />
                        <Route path="/training-due/default" element={<TrainingDueDefaultForm />} />
                        <Route path="/training-due/mep" element={<MepForm />} />
                        <Route path="/training-due/ft" element={<FtForm />} />
                        <Route path="/training-due/welding" element={<WeldingQvalForm />} />

                        {/* Safety stand down */}
                        <Route path="/ssd" element={<SSDDetail />} />

                        {/* Job Hazard Analysis */}
                        <Route path="/jha" element={<JHAPage />} />
                        <Route path="/jha/step-1" element={<JhaStepOne />} />
                        <Route path="/jha/step-2" element={<JhaStepTwo />} />
                        <Route path="/jha/step-3" element={<JhaStepThree />} />


                    </Routes>
                </Container>
            </Box>
            <Box sx={{ textAlign: 'right', padding: '20px' }}>
                <VersionStatus
                    onClick={() => {
                        if (waitingWorker) {
                            onShowUpdateModal();
                        }
                    }}
                />
            </Box>
        </Box>
    );
}


export default AuthenticatedLayout;
